import { ITranslationKeys } from '../../i18n/types';
import { IDatasetMetric } from '../common.types';
import { IMetricVideoLinkKeys } from '../videoMetrics.types';

export interface IMetricInfo {
  name: string;
  code: string;
  color: 'green' | 'red' | 'white';
  eng: string;
  description: string;
  data_type: string;
  default_data: boolean;
  sort_rev: boolean;
}

export interface IMetricParameterInfo {
  id: string;
  prefix: string;
  prefixLabel: string;
  label: ITranslationKeys;
  paramGroupLabel: ITranslationKeys;
}
export type IMetricParameterInfoRecord = Record<string, IMetricParameterInfo>;

export interface IMetricParameters {
  action_type: boolean;
  enter_exit_location: boolean;
  entry_type: boolean;
  exit_type: boolean;
  net_zones: boolean;
  puck_gain_type: boolean;
  shot_danger: boolean;
  shot_location: boolean;
  shot_type: boolean;
}

export const IMetricSectionName = {
  individual: 'individual',
  onIce: 'onIce',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IMetricSectionName = (typeof IMetricSectionName)[keyof typeof IMetricSectionName];

export interface IMetricGroupBase {
  id: string;
  groupName: string;
  groupNameEN: string;
}
export interface IMetricGroup extends IMetricGroupBase {
  metricParameters: IMetricParameters;
  metricOrder: number;
}
export type IMetricGroupRecord = Record<string, IMetricGroup>;

export interface IMetricSubgroup {
  id: string;
  name: string;
  nameEN: string;
}

export interface IMetric {
  id: string;
  metricInfo: IMetricInfo;
  metricGroups: IMetricGroupRecord;
  metricSubgroup: IMetricSubgroup;
  metricSectionId: IMetricSectionName;
  metricVideoLinkId: IMetricVideoLinkKeys | null;
}

export type IMetricRecord = Record<string, IMetric>;

export interface IMetricWithDatasetMetric {
  id: string;
  label: string;
  metric: IMetric;
  datasetMetric: IDatasetMetric;
}

export interface IMetricWithValue extends IMetricWithDatasetMetric {
  value: number;
}

interface IPlayerRankWithTotal {
  rank: number;
  total: number;
}

export interface IMetricWithRankValues extends IMetricWithDatasetMetric {
  teamRank: IPlayerRankWithTotal;
  overallRank: IPlayerRankWithTotal;
}

export type IMetricWithValueRecord = Record<string, IMetricWithValue>;
export type IMetricWithRankValuesRecord = Record<string, IMetricWithRankValues>;

export interface IMetricCategory {
  byId: IMetricRecord;
  allIds: string[];
}

export interface IMetrics {
  goalkeepers: IMetricCategory;
  players: IMetricCategory;
  teams: IMetricCategory;
}

export interface IMetricsState {
  metrics?: IMetrics;
  isLoading?: boolean;
  isAllMetricsLoaded?: boolean;
  error?: string;
}

// start of DTOs ----------------------
export interface IMetricInfoDTO {
  action_type: boolean;
  colour: 'green' | 'red' | 'white';
  data: string;
  data_group: string;
  default_data: boolean;
  desc: string;
  eng: string;
  enter_exit_location: boolean;
  entry_type: boolean;
  exit_type: boolean;
  name: string;
  net_zones: boolean;
  prod: string;
  puck_gain_type: boolean;
  shot_danger: boolean;
  shot_location: boolean;
  shot_type: boolean;
  sort_rev: boolean;
  type: string;
}

export interface IMetricSubgroupDTO {
  name: string;
  name_en: string;
  attributes: IMetricInfoDTO[];
}

export interface IMetricGroupDTO {
  name: string;
  name_en: string;
  types: IMetricSubgroupDTO[];
}

export type IMetricSectionRecordDTO = Record<string, IMetricGroupDTO[]>;

export interface IMetricCategoryDTO {
  individual: IMetricSectionRecordDTO[];
  onIce?: IMetricSectionRecordDTO[];
}

export interface IMetricsDTO {
  goalkeepers: IMetricCategoryDTO[];
  players: IMetricCategoryDTO[];
  teams: IMetricCategoryDTO[];
}
// end of DTOs ----------------------
// start of filtering types ---------
export interface ISectionMetrics {
  id: string;
  metrics: IMetric[];
}

export type ISectionMetricsRecord = Record<string, ISectionMetrics>;

export interface IMetricColumn {
  name: string;
  metrics: IMetric[];
  order: number;
}

export type IMetricColumnsRecord = Record<string, IMetricColumn>;

export interface IMetricRow {
  name: string;
  columns: IMetricColumnsRecord;
  order: number;
}

export type IMetricRowsRecord = Record<string, IMetricRow>;

export interface IMetricsSectionToDisplay {
  id: string;
  rows: IMetricRowsRecord;
}

export type IMetricsSectionsToDisplayRecord = Record<string, IMetricsSectionToDisplay>;

export type ISectionNames = {
  individualName?: ITranslationKeys;
  onIceName?: ITranslationKeys;
};

export interface IMetricVideoIds {
  metricLinkId: IMetricVideoLinkKeys;
  metricSubgroupId?: string;
  metricId?: string;
}
