import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  Loading,
  Playground,
  PlaygroundZoneContent,
  ToggleSelect,
} from '../../../../components';
import {
  DISABLED_TABS_ON_OFF,
  dumpInOutResultOptions,
  entityOptions,
  shotDangerOptions,
  zoneEntryCategoryOptions,
  zoneFinishOptions,
  zoneSuccessOptions,
} from '../../../../constants';
import {
  filteredVideomapsFilterDataSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneGameEntitiesSelector,
  selectGames,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectTeams,
  selectVideomapsFilter,
  selectZones,
  selectZonesFilter,
  setSelectedGames,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useExportAsImage,
  useFetchShotsOrPasses,
  useFetchZones,
  useHandleOnChange,
  useHandleOnSubmit,
  useLocationPaths,
  useVideoCenter,
  useZoneEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IFormation,
  ISelectOption,
  IZoneEntityOptions,
  IZonesFetchDataMode,
} from '../../../../types';
import {
  changeUrlSearchParamsMultiselectInput,
  computePlaygroundTotalShots,
  computePlaygroundTotalXg,
  createClassNames,
  filterDumpInOutGoals,
  getGamesOptions,
  getPlayerPlaygroundBoxProps,
  getZoneDangerBoxes,
  isAtLeastOnePlayerOn,
  isPlayerSelected,
} from '../../../../utils';
import './ZoneEntriesContent.styles.scss';

const classNames = createClassNames('videomaps-zone-entries-content');

export const ZoneEntriesContent = () => {
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { entity, selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { dumpInOutResult, zoneCategory, zoneSuccess, zoneFinish } =
    useAppSelector(selectZonesFilter);
  const { shotDanger } = useAppSelector(selectMetricParamsFilter);
  const { filteredGamesTotalToi } = useAppSelector(filteredVideomapsFilterDataSelector);
  const { areZonesLoading } = useAppSelector(selectZones);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneEntities = useAppSelector(state =>
    filteredZoneGameEntitiesSelector(state, zoneEntries),
  );
  const dispatch = useAppDispatch();

  const { activeTab } = useLocationPaths();
  const [, setSearchParams] = useSearchParams();

  const {
    onChangeEntity,
    onChangeZoneCategory,
    onChangeShotDanger,
    onChangeZoneSuccess,
    onChangeZoneFinish,
    onChangeDumpInOutResult,
  } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchZoneEntries } = useFetchZones();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    zoneEntities,
    ITranslationKeys.zoneEntries,
  );

  const [showPlaygroundBoxes, setShowPlaygroundBoxes] = useState(false);

  const isInitialMountRef = useRef(true);

  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-karta-hráče`, 'png');

  const handleOnSubmitZones = useHandleOnSubmit(values => {
    fetchZoneEntries(values, {
      mode:
        zoneCategory.value === 'dumpInOut'
          ? IZonesFetchDataMode.dumpInOut
          : IZonesFetchDataMode.entry,
    });
  }, isInitialMountRef);

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
  }, isInitialMountRef);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const formation: IFormation[] = useMemo(() => {
    const filteredSelectedPlayers = Object.values(selectedPlayerItems).filter(
      item => item.selectedPlayer,
    );
    const filteredPlayers = filteredSelectedPlayers.map(item => ({
      player: item.selectedPlayer?.value || '',
      on: item.isActive ?? false,
    }));
    return filteredPlayers;
  }, [selectedPlayerItems]);

  useZoneEffects(
    handleOnSubmit,
    handleOnSubmitZones,
    isInitialMountRef,
    formation,
    games.isLoading,
  );

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);

  if (games.isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  const selectedEntity: IZoneEntityOptions = entity.value === 'player' ? 'formation' : 'team';
  const disabledOnOff = DISABLED_TABS_ON_OFF.includes(activeTab);
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  const isValidFormation = formation.length > 0 && isSomePlayerOn;
  const filteredPlayers = Object.values(selectedPlayerItems).filter(player =>
    player.selectedPlayer?.value
      ? isPlayerSelected(selectedPlayerItems, player.selectedPlayer.value, disabledOnOff)
      : false,
  );
  const { leftBox, centerBox, rightBox, summaryBox } = getPlayerPlaygroundBoxProps(
    zoneEntries,
    formation,
    selectedEntity,
    selectedPlayerItems,
    filteredPlayers,
    entity.value,
    players.byId,
  );

  return (
    <div ref={exportRef} className={classNames()} data-testid='videomaps-page__passes-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={zoneEntities}
          selectedPlayerItems={selectedPlayerItems}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGamesTotalToi}
            c={computePlaygroundTotalShots(zoneEntries, formation, entity.value)}
            xG={computePlaygroundTotalXg(zoneEntries, formation, entity.value)}
            g={filterDumpInOutGoals(zoneEntries, formation, entity.value)}
            boxes={getZoneDangerBoxes(zoneEntries, formation, entity.value)}
            boxListHeading={ITranslationKeys.entryDanger}
            renderContent={() => {
              if (areZonesLoading) {
                return <Loading />;
              }

              return (
                <PlaygroundZoneContent
                  leftBox={leftBox}
                  centerBox={centerBox}
                  rightBox={rightBox}
                  summaryBox={summaryBox}
                  showBoxes={showPlaygroundBoxes}
                  onClick={playVideos}
                  isZoneEntry
                />
              );
            }}
            onPrintIconClick={exportImage}
            onBoxesAppearanceChange={setShowPlaygroundBoxes}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.whoEntersToOffensiveZone} />
            <ToggleSelect
              selected={entity}
              options={entityOptions}
              onChange={newOption => onChangeEntity(newOption)}
              disabledOptions={!isValidFormation ? [entityOptions[0]] : undefined}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.entryCategory} />
            <ToggleSelect
              selected={zoneCategory}
              options={zoneEntryCategoryOptions}
              onChange={newOption => onChangeZoneCategory(newOption)}
              hasOnlyOneColumn
            />
          </div>
          {zoneCategory.value !== 'dumpInOut' ? (
            <div>
              <Caption label={ITranslationKeys.bySuccess} />
              <ToggleSelect
                selected={zoneSuccess}
                options={zoneSuccessOptions}
                onChange={newOption => onChangeZoneSuccess(newOption)}
                twoColumns
              />
            </div>
          ) : (
            <div>
              <Caption label={ITranslationKeys.dumpinResult} />
              <ToggleSelect
                selected={dumpInOutResult}
                options={dumpInOutResultOptions}
                onChange={newOption => onChangeDumpInOutResult(newOption)}
                hasOnlyOneColumn
              />
            </div>
          )}
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption
              label={
                zoneCategory.value !== 'dumpInOut'
                  ? ITranslationKeys.entryDanger
                  : ITranslationKeys.dumpinDanger
              }
            />
            <ToggleSelect
              selected={shotDanger}
              options={shotDangerOptions}
              onChange={newOption => onChangeShotDanger(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.finish} />
            <ToggleSelect
              selected={zoneFinish}
              options={zoneFinishOptions}
              onChange={newOption => onChangeZoneFinish(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div className={classNames('main__form-box__save-button')}>
            <Button
              label={ITranslationKeys.saveMap}
              iconComponent={<DownloadIcon />}
              onClick={exportImage}
              disabled={isExporting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
