import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  ColorHeatmapBar,
  GamesSelectList,
  Heatmap,
  HeatmapComparative,
  Loading,
  Playground,
  ToggleSelect,
} from '../../../../components';
import {
  comparisonTypeOptions,
  entityOptions,
  shotCategoryOptions,
  shotGameActionTypeOptions,
} from '../../../../constants';
import {
  filteredShotsSelector,
  filteredVideomapsFilterDataSelector,
  selectGames,
  selectMainFilter,
  selectMetricParamsFilter,
  selectShots,
  selectTeams,
  selectVideomapsFilter,
  setComparisonType,
  setSelectedGames,
  triggerContentReload,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchShotsOrPasses,
  useHandleOnChange,
  useHandleOnSubmit,
  useRefetchContent,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { LoadIcon } from '../../../../icons';
import { ICoordinates, ISelectOption } from '../../../../types';
import {
  calculateShotsTotalXG,
  changeUrlSearchParamsMultiselectInput,
  createClassNames,
  filterGoals,
  getGamesOptions,
  getShotsDangerBoxes,
  parseUrlSelectedGames,
} from '../../../../utils';
import './HeatmapContent.styles.scss';

const classNames = createClassNames('videomaps-heatmap-content');

export const HeatmapContent = () => {
  const { isLoading, isHeatmapLoading } = useAppSelector(selectShots);
  const { filteredGamesTotalToi, filteredHeatmapByEntity, heatmapMetric } = useAppSelector(
    filteredVideomapsFilterDataSelector,
  );
  const filteredShots = useAppSelector(filteredShotsSelector);
  const teams = useAppSelector(selectTeams);
  const { games } = useAppSelector(selectGames);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { entity, shotCategory, comparisonType, selectedPlayerItems } =
    useAppSelector(selectVideomapsFilter);
  const { gameActionType } = useAppSelector(selectMetricParamsFilter);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { onChangeShotCategory, onChangeEntity, onChangeGameActionType } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses, fetchShots, fetchHeatmap } = useFetchShotsOrPasses();

  const isInitialMountRef = useRef(true);

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
  }, isInitialMountRef);

  const handleOnSubmitAfterGames = useHandleOnSubmit((values, config) => {
    fetchShots(values);
    fetchHeatmap(values, config?.isInitHeatmap);
  }, isInitialMountRef);

  const handleLoadHeatmap = useHandleOnSubmit(values => {
    fetchHeatmap(values);
  }, isInitialMountRef);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  useEffect(() => {
    if (Object.keys(games.byId).length > 0) {
      const gamesOptions = getGamesOptions(games.byId);
      const selectedGames = parseUrlSelectedGames(searchParams, gamesOptions);

      dispatch(setSelectedGames(selectedGames || gamesOptions));
      handleOnSubmitAfterGames({ isInitHeatmap: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games.byId]);

  useRefetchContent({ handleOnSubmit, isLoading });

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const handleChangeEntity = (newValue: ISelectOption, prevValue: ISelectOption) => {
    onChangeEntity(newValue);
    if (
      comparisonType?.value !== 'without' &&
      ((newValue.value === 'player' && prevValue.value !== 'player') ||
        (newValue.value !== 'player' && prevValue.value === 'player'))
    ) {
      dispatch(triggerContentReload());
    }
  };

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const activeSelectedPlayers = Object.values(selectedPlayerItems).filter(
    player => player.isActive,
  );

  if (activeSelectedPlayers.length === 0 && entity.value === 'player') {
    const teamEntity = entityOptions.find(option => option.value === 'team');
    if (teamEntity) {
      onChangeEntity(teamEntity);
    }
  }

  if (isLoading || games.isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  const entityPlayerOption = entityOptions.find(option => option.value === 'player');
  const isComparisonTypeChance = comparisonType?.value === 'chance';
  const isComparisonTypeWithout = comparisonType?.value === 'without';

  return (
    <div className={classNames()} data-testid='videomaps-page__heatmap-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          onChange={newValue => handleChangeGame(newValue)}
          hasSelectAllOption
          shouldCheckFullBody
          isHeatmap
        />
        <div
          className={classNames('main__color-heatmap-bar', {
            hidden: isComparisonTypeWithout,
          })}
        >
          <ColorHeatmapBar />
        </div>
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGamesTotalToi}
            c={filteredShots.length}
            xG={calculateShotsTotalXG(filteredShots)}
            g={filterGoals(filteredShots).length}
            boxes={getShotsDangerBoxes(filteredShots)}
            boxListHeading={ITranslationKeys.shotsDanger}
            renderContent={() => {
              if (isHeatmapLoading) {
                return <Loading />;
              }

              if (filteredHeatmapByEntity) {
                if (comparisonType?.value === 'without') {
                  const shotsData: ICoordinates[] = filteredShots.map(shot => ({
                    x: shot.x,
                    y: shot.y,
                  }));

                  return <Heatmap data={shotsData} style={{ borderRadius: '200px 200px 0 0' }} />;
                }

                return (
                  <HeatmapComparative
                    data={filteredHeatmapByEntity}
                    metric={heatmapMetric}
                    isChance={comparisonType?.value === 'chance'}
                  />
                );
              }
            }}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.whoShoots} />
            <ToggleSelect
              selected={entity}
              options={entityOptions}
              onChange={newValue => handleChangeEntity(newValue, entity)}
              disabledOptions={
                activeSelectedPlayers.length === 0
                  ? entityPlayerOption
                    ? [entityPlayerOption]
                    : undefined
                  : undefined
              }
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotCategory} />
            <ToggleSelect
              selected={isComparisonTypeChance ? undefined : shotCategory}
              options={shotCategoryOptions}
              onChange={newOption => onChangeShotCategory(newOption)}
              twoColumns
              disabledOptions={isComparisonTypeChance ? shotCategoryOptions : undefined}
            />
          </div>
          <Button
            label={ITranslationKeys.loadHeatmap}
            variant='normal-underlined'
            iconComponent={<LoadIcon />}
            onClick={() => handleLoadHeatmap()}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.attackType} />
            <ToggleSelect
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              onChange={newOption => onChangeGameActionType(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.compareWithLeague} />
            <ToggleSelect
              selected={comparisonType}
              options={comparisonTypeOptions}
              onChange={newValue => dispatch(setComparisonType(newValue))}
              hasOnlyOneColumn
            />
          </div>
        </div>
      </div>
    </div>
  );
};
