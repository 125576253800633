import { createSelector } from '@reduxjs/toolkit';

import { ISelectOption, ISelectOptionBase, ISelectedPlayerItem, IZoneEntity } from '../../types';
import { createZoneGameEntities, filterByEntity, filterBySelectedGames } from '../../utils';
import {
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectVideomapsFilter,
  selectZones,
  selectZonesFilter,
} from '../selectors';

const filterZonesByPass = (zoneEntities: IZoneEntity[]) => {
  if (!zoneEntities) return [];

  return zoneEntities.filter(zoneEntity => zoneEntity.type !== 'pass');
};

const isDumpPlayerSatisfied = (
  dump: IZoneEntity,
  entity: ISelectOptionBase,
  filteredPlayers: ISelectedPlayerItem[],
) => {
  const dumpBelongsToSelectedPlayer = filteredPlayers.find(
    player => player.selectedPlayer?.value === dump.playerId,
  );
  return dumpBelongsToSelectedPlayer || entity.value !== 'player';
};

const filterZonesBySuccess = (zoneEntities: IZoneEntity[], zoneSuccessValue: string) => {
  if (zoneSuccessValue === 'successfully') {
    return zoneEntities.filter(zoneEntity => zoneEntity.successful);
  }

  if (zoneSuccessValue === 'blocked') {
    return zoneEntities.filter(zoneEntity => !!zoneEntity.blocker);
  }

  return zoneEntities.filter(zoneEntity => !zoneEntity.successful);
};

const filterZoneEntitiesByDanger = (
  zoneEntities: IZoneEntity[],
  zoneEntityDanger: ISelectOption,
) => {
  const zoneEntityDangerValue = zoneEntityDanger.value.toUpperCase();
  return zoneEntities.filter(zoneEntity => zoneEntity.shotDanger === zoneEntityDangerValue);
};

const filterZonesByFinish = (zoneEntities: IZoneEntity[], zoneFinishValue: string) => {
  if (!zoneEntities) return [];

  if (zoneFinishValue === 'shot') {
    return zoneEntities.filter(zoneEntity => zoneEntity.finish && zoneEntity.finish !== 'none');
  }
  if (zoneFinishValue === 'shotFromSlot') {
    return zoneEntities.filter(zoneEntity => zoneEntity.shotInSlot);
  }
  if (zoneFinishValue === 'goal') {
    return zoneEntities.filter(zoneEntity => zoneEntity.finish === 'goal');
  }

  return zoneEntities;
};

const filterZoneExitsByResult = (zoneEntities: IZoneEntity[], zoneResultValue: string) => {
  if (!zoneEntities) return [];

  if (zoneResultValue === 'offensiveZoneGain') {
    return zoneEntities.filter(zoneEntity => zoneEntity.followingEvent === 'gain');
  }
  if (zoneResultValue === 'losingPuckInMiddleZone') {
    return zoneEntities.filter(zoneEntity => zoneEntity.followingEvent === 'denial');
  }

  return zoneEntities;
};

const filterDumpInOutsByResultSelector = (
  dumpEntities: IZoneEntity[],
  dumpResultValue: string,
  entity: ISelectOptionBase,
  filteredPlayers: ISelectedPlayerItem[],
) => {
  if (dumpResultValue === 'battle') {
    return dumpEntities.filter(dump => dump.battle);
  }

  if (dumpResultValue === 'battleWon') {
    return dumpEntities.filter(dump => dump.battleWon);
  }

  if (dumpResultValue === 'battleLost') {
    return dumpEntities.filter(
      dump =>
        dump.battle && !dump.battleWon && isDumpPlayerSatisfied(dump, entity, filteredPlayers),
    );
  }

  return dumpEntities;
};

// Start of shared selectors
export const filteredZonesByEntitySelector = createSelector(
  [selectVideomapsFilter, selectZones],
  ({ entity }, { zones }) => filterByEntity(entity, zones.for, zones.against),
);

export const filteredZonesByGamesSelector = createSelector(
  [
    selectVideomapsFilter,
    selectMainFilter,
    filteredZonesByEntitySelector,
    (_, customGames?: ISelectOption[]) => customGames,
  ],
  (videomapsFilterState, { selectedGames }, filteredZonesByEntity, customGames) => {
    if (videomapsFilterState.entityId) {
      return filteredZonesByEntity;
    }

    return filterBySelectedGames(
      videomapsFilterState,
      filteredZonesByEntity,
      selectedGames,
      customGames,
    );
  },
);

export const filteredZonesBySuccessSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneSuccess }, filteredZonesByEntity) => {
    if (zoneSuccess.value === 'all') return filteredZonesByEntity;

    return filterZonesBySuccess(filteredZonesByEntity, zoneSuccess.value);
  },
);

export const filteredZonesByDangerSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ shotDanger }, filteredZonesByEntity) => {
    if (shotDanger.value === 'all') return filteredZonesByEntity;

    return filterZoneEntitiesByDanger(filteredZonesByEntity, shotDanger);
  },
);

export const filteredZonesByLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ enterExitLocation }, filteredZonesByEntity) => {
    if (enterExitLocation.value === 'all') return filteredZonesByEntity;

    if (enterExitLocation.value === 'le') {
      return filteredZonesByEntity.filter(zone => zone.location === 'left');
    }
    if (enterExitLocation.value === 'mi') {
      return filteredZonesByEntity.filter(zone => zone.location === 'center');
    }
    if (enterExitLocation.value === 'ri') {
      return filteredZonesByEntity.filter(zone => zone.location === 'right');
    }

    return filteredZonesByEntity;
  },
);

export const filteredZonesByShotLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ shotLocation }, filteredZonesByEntity) => {
    if (shotLocation.value === 'all') return filteredZonesByEntity;

    if (shotLocation.value === 'slot' || shotLocation.value === 'sslot') {
      return filteredZonesByEntity.filter(zone => zone.shotInSlot);
    }

    return filteredZonesByEntity;
  },
);

export const filteredZonesByFinishSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneFinish }, filteredZonesByEntity) => {
    if (zoneFinish.value === 'all') return filteredZonesByEntity;

    return filterZonesByFinish(filteredZonesByEntity, zoneFinish.value);
  },
);

export const filteredZonesByPassSelector = createSelector(
  [filteredZonesByEntitySelector],
  filteredZonesByEntity => filterZonesByPass(filteredZonesByEntity),
);

export const filteredZonesByShotPlayerIdSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ shotPlayerId }, filteredZonesByEntity) => {
    if (!shotPlayerId) return filteredZonesByEntity;

    return filteredZonesByEntity.filter(zoneEntity => zoneEntity.shot?.player === shotPlayerId);
  },
);

export const filteredZonesSelector = createSelector(
  [
    filteredZonesByGamesSelector,
    filteredZonesBySuccessSelector,
    filteredZonesByDangerSelector,
    filteredZonesByLocationSelector,
    filteredZonesByShotLocationSelector,
    filteredZonesByFinishSelector,
    filteredZonesByPassSelector,
    filteredZonesByShotPlayerIdSelector,
  ],
  (
    filteredZonesByGames,
    filteredZonesBySuccess,
    filteredZonesByDanger,
    filteredZonesByLocation,
    filteredZonesByShotLocation,
    filteredZonesByFinish,
    filteredZonesByPass,
    filteredZonesByShotPlayerId,
  ) => {
    const filteredZonesByShared = filteredZonesByGames.filter(zone => {
      return (
        filteredZonesBySuccess.includes(zone) &&
        filteredZonesByDanger.includes(zone) &&
        filteredZonesByLocation.includes(zone) &&
        filteredZonesByShotLocation.includes(zone) &&
        filteredZonesByFinish.includes(zone) &&
        filteredZonesByPass.includes(zone) &&
        filteredZonesByShotPlayerId.includes(zone)
      );
    });

    return filteredZonesByShared;
  },
);

export const filteredZoneGameEntitiesSelector = createSelector(
  [selectMainFilter, selectPlayers, (_, filteredZones: IZoneEntity[]) => filteredZones],
  ({ selectedGames }, playerState, filteredZones) => {
    if (!filteredZones) return [];

    return createZoneGameEntities(selectedGames, filteredZones, playerState.byId);
  },
);
// End of shared selectors
// Start of exits selectors
export const filteredZoneExitsByResultSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneExitResult }, filteredZonesByEntity) => {
    if (zoneExitResult.value === 'all') return filteredZonesByEntity;

    return filterZoneExitsByResult(filteredZonesByEntity, zoneExitResult.value);
  },
);
// End of exits selectors
// Beginning of the dumpin selectors
export const filteredZoneDumpInOutsByResultSelector = createSelector(
  [selectVideomapsFilter, selectZonesFilter, filteredZonesByEntitySelector],
  (videomapsFilterState, { dumpInOutResult }, zoneDumpInOuts) => {
    const { entity, selectedPlayerItems } = videomapsFilterState;

    if (dumpInOutResult.value === 'all') return zoneDumpInOuts;

    const filteredPlayers = Object.values(selectedPlayerItems).filter(item => item.selectedPlayer);

    return filterDumpInOutsByResultSelector(
      zoneDumpInOuts,
      dumpInOutResult.value,
      entity,
      filteredPlayers,
    );
  },
);
// End of the zone dumpin selectors
// Start of shared summary selectors
export const filteredUncontrolledZonesDataSelector = createSelector(
  [
    filteredZonesByDangerSelector,
    filteredZoneDumpInOutsByResultSelector,
    filteredZonesByFinishSelector,
  ],
  (filteredDumpInOutsByDanger, filteredZoneDumpInOutsByResult, filteredZonesByFinish) =>
    filteredDumpInOutsByDanger.filter(
      zone => filteredZoneDumpInOutsByResult.includes(zone) && filteredZonesByFinish.includes(zone),
    ),
);
// End of shared summary selectors
// Start of entries summary selectors
export const filteredZoneEntriesFilterDataSelector = createSelector(
  [selectZonesFilter, filteredZonesSelector, filteredUncontrolledZonesDataSelector],
  ({ zoneCategory }, filteredControlledZoneEntries, filteredUncontrolledZones) => {
    if (zoneCategory.value === 'controlled') {
      return filteredControlledZoneEntries;
    }

    if (zoneCategory.value === 'all') {
      return filteredControlledZoneEntries.filter(zone => filteredUncontrolledZones.includes(zone));
    }

    return filteredUncontrolledZones;
  },
);
// End of entries summary selectors
// Start of exits summary selectors
export const filteredControlledZoneExitsDataSelector = createSelector(
  [filteredZonesSelector, filteredZoneExitsByResultSelector],
  (filteredZones, filteredZoneExitsByResult) =>
    filteredZones.filter(zone => filteredZoneExitsByResult.includes(zone)),
);

export const filteredZoneExitsFilterDataSelector = createSelector(
  [
    selectZonesFilter,
    filteredControlledZoneExitsDataSelector,
    filteredUncontrolledZonesDataSelector,
  ],
  ({ zoneCategory }, filteredControlledZoneExits, filteredUncontrolledZoneExits) => {
    if (zoneCategory.value === 'controlled') {
      return filteredControlledZoneExits;
    }

    if (zoneCategory.value === 'all') {
      return filteredControlledZoneExits.filter(zone =>
        filteredUncontrolledZoneExits.includes(zone),
      );
    }

    return filteredUncontrolledZoneExits;
  },
);
