import { goalkeeperSavesOptions, stickGripToggleOptions } from '../../constants';
import { IGoalkeepersFilterState, IUrlParam } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { parseUrlStaticSelectOption } from '../url.utils';

export const parseUrlGoalkeepersFilterParams = (
  searchParams: URLSearchParams,
): Partial<IGoalkeepersFilterState> => {
  const saves = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.goalkeeperSaves,
    goalkeeperSavesOptions,
  );
  const goalkeeperStickGrip = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.goalkeeperStickGrip,
    stickGripToggleOptions,
  );

  return {
    saves: saves || getDefaultPredefinedOptionByValue(goalkeeperSavesOptions),
    stickGrip: goalkeeperStickGrip || getDefaultPredefinedOptionByValue(stickGripToggleOptions),
  };
};
