import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IFaceoffPlayerInfo, IFaceoffRequestBody } from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseFaceoffsData } from './parseFaceoffs';

interface IGetFaceoffsParams {
  competitionsUuids: string[];
  teamUuid: string;
  playerUuid?: string;
  body?: IFaceoffRequestBody;
  zone?: string;
  spot?: string;
  isTeammate?: boolean;
  isOpponent?: boolean;
}

const getFaceoffsRequestUrl = (params: Omit<IGetFaceoffsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, playerUuid, zone, spot, isTeammate, isOpponent } = params;
  const base = playerUuid
    ? `/faceOff/${competitionsUuids}/${teamUuid}/${playerUuid}`
    : `/faceOff/${competitionsUuids}/${teamUuid}`;

  return buildPath(base, [
    urlBooleanParamResolver(isTeammate, 'teammate'),
    urlBooleanParamResolver(isOpponent, 'opponent'),
    urlBooleanParamResolver(!!zone, 'zone'),
    urlBooleanParamResolver(!!spot, 'spot'),
    zone,
    spot,
  ]);
};

export const getFaceoffs = createAsyncThunk<IFaceoffPlayerInfo, IGetFaceoffsParams>(
  'faceoffs/getFaceoffs',
  async ({ competitionsUuids, teamUuid, playerUuid, body, zone, spot, isTeammate, isOpponent }) => {
    const requestPath = getFaceoffsRequestUrl({
      competitionsUuids,
      teamUuid,
      playerUuid,
      zone,
      spot,
      isTeammate,
      isOpponent,
    });

    const faceoffs = await axiosInstance
      .post(requestPath, body)
      .then(response => parseFaceoffsData(response.data, playerUuid))
      .catch(defaultCatchErrorCallback);

    return faceoffs;
  },
);
