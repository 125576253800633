import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Caption,
  CommonTable,
  DataSettingsModal,
  FormationTypeController,
  Loading,
  SelectInput,
  TableToolbar,
} from '../../../../components';
import {
  DEFAULT_CONTENT_TEMPLATE_NAMES,
  defaultTemplate,
  formationTypeOptionsWithAllSituations,
  gamePartDefaultOption,
} from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGamesTablesFormations,
  resetDataSettingsFilterState,
  selectDataSettingsFilter,
  selectGames,
  selectMainFilter,
  selectMetrics,
  selectTableFilter,
  selectTeams,
  selectedGameSelector,
  setCustomMetrics,
  setDataTemplate,
  setGamePart,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IGamesTablesFormationsTableData,
  IGamesTablesStatsRequestBody,
  IMainFilterForm,
  ISelectOption,
} from '../../../../types';
import {
  createClassNames,
  createTeamSortingOptions,
  getTimeFromGamePart,
  normalizeSelectWithAllValues,
} from '../../../../utils';
import './GameFormationsContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchGameFormationsStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { gamePart } = useAppSelector(selectTableFilter);
  const { filteredParts, selectedGame } = useAppSelector(filteredMainFilterDataSelector);
  const { formationType } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGameFormations = (data: Partial<IMainFilterForm>) => {
    if (!selectedGame) return;

    const competitionsUuids = filteredParts.map(part => part.id);
    const timeFromTo = getTimeFromGamePart(gamePart);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamesTablesStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES['formations']),
      gameState: normalizeSelectWithAllValues(formationType),
      timeFrom: timeFromTo?.timeFrom,
      timeTo: timeFromTo?.timeTo,
    };
    dispatch(
      getGamesTablesFormations({
        competitionsUuids,
        matchUuid: selectedGame.id,
        teamUuid: selectedGame.homeTeamId,
        body: requestBody,
      }),
    );
  };

  return fetchGameFormations;
};

const classNames = createClassNames('game-formations-content');

export const GameFormationsContent = () => {
  const selectedGame = useAppSelector(selectedGameSelector);
  const { formationType } = useAppSelector(selectMainFilter);
  const { gamePart, dataTemplate } = useAppSelector(selectTableFilter);
  const { gamesTables, games } = useAppSelector(selectGames);
  const { byId } = useAppSelector(selectTeams);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const { formationsLoading } = gamesTables;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const filteredTeamOptions: ISelectOption<any>[] = createTeamSortingOptions(byId, selectedGame);
  const [selectedFilterTeamId, setSelectedFilterTeamId] = useState<
    ISelectOption<any> | undefined
  >();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable(selectedFilterTeamId?.value);
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchGameFormationsStats = useFetchGameFormationsStats();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGameFormationsStats(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading: formationsLoading, disableInitialRefetch: true });

  useEffect(() => {
    setSelectedFilterTeamId(filteredTeamOptions[0]);
    dispatch(setGamePart(gamePartDefaultOption));

    if (selectedGame) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  useEffect(() => {
    handleOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formationType, gamePart, dataTemplate]);

  useEffect(() => {
    return () => {
      dispatch(setDataTemplate(defaultTemplate));
      dispatch(setCustomMetrics(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formationsLoading || !games.isAllGamesLoaded) {
    return <Loading />;
  }

  if (!selectedGame) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectGame)}</div>;
  }

  const renderTableOrInfoBox = () => {
    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) return errorInfoBox;

    return (
      <CommonTable<IGamesTablesFormationsTableData>
        centerPartRef={centerTablePartRef}
        cellsHeight={67}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='games-page__tabs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        disablePositionSelection
        extraElement={
          <>
            <FormationTypeController
              captionTranslationKey={ITranslationKeys.countOfPlayers}
              options={formationTypeOptionsWithAllSituations}
            />
            <div>
              <Caption label={ITranslationKeys.filterTeam} />
              <SelectInput
                variant='filter'
                options={filteredTeamOptions}
                selected={selectedFilterTeamId}
                onChange={setSelectedFilterTeamId}
              />
            </div>
          </>
        }
        scrollSizePx={130}
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(resetDataSettingsFilterState())}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
    </div>
  );
};
