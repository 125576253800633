import {
  INITIAL_SELECTED_PLAYER_ITEMS,
  countOfPlayersOptions,
  entityOptions,
  formationTypeOptionsWithAllSituations,
  passTypeOptions,
  placeOptions,
  scoreStateOptions,
  shotCategoryOptions,
} from '../../constants';
import {
  ICompetitionsDetailRecord,
  IGoalkeeperRecord,
  IMainFilterForm,
  IPlayerRecord,
  ISeasonRecord,
  ITeamRecord,
  IUrlParam,
  IVideomapsFilterState,
} from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { filterParts, filterTeams } from '../mainFilter.utils';
import {
  parseUrlDateRange,
  parseUrlNumberValue,
  parseUrlOpponentsAtGamesPage,
  parseUrlSelectedGoalkeeper,
  parseUrlSelectedOpponents,
  parseUrlSelectedParts,
  parseUrlSelectedPlayer,
  parseUrlSelectedPlayerItems,
  parseUrlSelectedSeasons,
  parseUrlSelectedTeam,
  parseUrlStaticSelectOption,
} from '../url.utils';

export const parseUrlDynamicFiltersParams = (
  searchParams: URLSearchParams,
  defaultData: IMainFilterForm,
  seasonRecord: ISeasonRecord,
  competitonsDetailRecord: ICompetitionsDetailRecord,
  teamRecord: ITeamRecord,
  playerRecord: IPlayerRecord,
  goalkeeperRecord: IGoalkeeperRecord,
) => {
  const selectedSeasonsParams = parseUrlSelectedSeasons(searchParams, seasonRecord);
  const selectedSeasons =
    selectedSeasonsParams.length > 0 ? selectedSeasonsParams : defaultData.selectedSeasons;

  const selectedPartsParams = parseUrlSelectedParts(searchParams, competitonsDetailRecord);
  const selectedParts =
    selectedPartsParams.length > 0 ? selectedPartsParams : defaultData.selectedParts;

  const filteredParts = filterParts(
    competitonsDetailRecord,
    selectedSeasons.map(season => season.value),
    selectedParts.map(part => part.value),
  );
  const filteredTeams = filterTeams(teamRecord, filteredParts);

  const selectedTeamParams = parseUrlSelectedTeam(searchParams, filteredTeams);
  const selectedTeam = selectedTeamParams || defaultData.selectedTeam;

  const selectedPlayer = parseUrlSelectedPlayer(
    searchParams,
    playerRecord,
    filteredParts,
    selectedTeam?.value,
  );
  const selectedGoalkeeper = parseUrlSelectedGoalkeeper(
    searchParams,
    goalkeeperRecord,
    filteredParts,
    selectedTeam?.value,
  );
  const selectedOpponents = parseUrlSelectedOpponents(
    searchParams,
    filteredTeams,
    selectedTeam?.value,
  );
  const opponentsAtGamesPage = parseUrlOpponentsAtGamesPage(
    searchParams,
    filteredTeams,
    selectedTeam?.value,
  );

  const countOfPlayers = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.countOfPlayers,
    countOfPlayersOptions,
  );
  const place = parseUrlStaticSelectOption(searchParams, IUrlParam.place, placeOptions);
  const scoreState = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.scoreState,
    scoreStateOptions,
  );
  const formationType = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.formationType,
    formationTypeOptionsWithAllSituations,
  );
  const lastPlayedMatches = parseUrlNumberValue(searchParams, IUrlParam.lastPlayedMatches);
  const gameTimeFrom = parseUrlNumberValue(searchParams, IUrlParam.gameTimeFrom);
  const gameTimeTo = parseUrlNumberValue(searchParams, IUrlParam.gameTimeTo);
  const timeOnIce = parseUrlNumberValue(searchParams, IUrlParam.timeOnIce);
  const dateFromTo = parseUrlDateRange(searchParams, IUrlParam.dateFromTo);

  const mainFilter: IMainFilterForm = {
    selectedSeasons,
    selectedParts,
    selectedTeam,
    opponentsAtGamesPage: opponentsAtGamesPage || defaultData.opponentsAtGamesPage,
    selectedPlayer: selectedPlayer || defaultData.selectedPlayer,
    selectedGoalkeeper: selectedGoalkeeper || defaultData.selectedGoalkeeper,
    selectedOpponents: selectedOpponents || defaultData.selectedOpponents,
    countOfPlayers: countOfPlayers || defaultData.countOfPlayers,
    lastPlayedMatches: lastPlayedMatches || defaultData.lastPlayedMatches,
    dateFromTo: dateFromTo || defaultData.dateFromTo,
    place: place || defaultData.place,
    scoreState: scoreState || defaultData.scoreState,
    gameTimeFrom: gameTimeFrom || defaultData.gameTimeFrom,
    gameTimeTo: gameTimeTo || defaultData.gameTimeTo,
    timeOnIce: timeOnIce || defaultData.timeOnIce,
    selectedGames: defaultData.selectedGames,
    formationType: formationType || defaultData.formationType,
  };

  const selectedPlayerItems = parseUrlSelectedPlayerItems(
    searchParams,
    playerRecord,
    filteredParts,
    selectedTeam?.value,
  );
  const entity = parseUrlStaticSelectOption(searchParams, IUrlParam.entity, entityOptions);
  const shotCategory = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shotCategory,
    shotCategoryOptions,
  );
  const passType = parseUrlStaticSelectOption(searchParams, IUrlParam.passType, passTypeOptions);

  const videomapsFilter: Partial<IVideomapsFilterState> = {
    selectedPlayerItems: selectedPlayerItems || INITIAL_SELECTED_PLAYER_ITEMS,
    entity: entity || getDefaultPredefinedOptionByValue(entityOptions, 'team'),
    shotCategory: shotCategory || getDefaultPredefinedOptionByValue(shotCategoryOptions),
    passType: passType || getDefaultPredefinedOptionByValue(passTypeOptions),
  };

  return { mainFilter, videomapsFilter };
};
