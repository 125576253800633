import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  goalkeeperSavesOptions,
  shotAssistOptions,
  shotAssistTypeOptions,
  stickGripToggleOptions,
} from '../../../constants';
import { IGoalkeepersFilterState, ISelectOption } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IGoalkeepersFilterState = {
  saves: getDefaultPredefinedOptionByValue(goalkeeperSavesOptions),
  stickGrip: getDefaultPredefinedOptionByValue(stickGripToggleOptions),
  shotAssist: getDefaultPredefinedOptionByValue(shotAssistOptions),
  shotAssistType: getDefaultPredefinedOptionByValue(shotAssistTypeOptions),
};

export const goalkeepersFilterSlice = createSlice({
  name: 'goalkeepersFilter',
  initialState,
  reducers: {
    setGoalkeeperSaves: (state, action: PayloadAction<ISelectOption>) => {
      state.saves = action.payload;
    },
    setGoalkeeperStickGrip: (state, action: PayloadAction<ISelectOption>) => {
      state.stickGrip = action.payload;
    },
    setGoalkeeperShotAssist: (state, action: PayloadAction<ISelectOption>) => {
      state.shotAssist = action.payload;
    },
    setGoalkeeperShotAssistType: (state, action: PayloadAction<ISelectOption>) => {
      state.shotAssistType = action.payload;
    },
    resetGoalkeepersFilter: (
      _,
      action: PayloadAction<Partial<IGoalkeepersFilterState> | undefined>,
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setGoalkeeperSaves,
  setGoalkeeperStickGrip,
  setGoalkeeperShotAssist,
  setGoalkeeperShotAssistType,
  resetGoalkeepersFilter,
} = goalkeepersFilterSlice.actions;
