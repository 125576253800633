import { CSSProperties, FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SELECT_PLAYERS_INPUT_COLORS } from '../../../constants';
import { selectVideomapsFilter, setSelectedPlayerItems } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { IOnOffColors, IPlayerColors, ISelectOption, IUrlParam } from '../../../types';
import { createClassNames } from '../../../utils';
import { SelectInput } from '../../SelectInput';
import './SelectPlayersBar.styles.scss';

export interface ISelectPlayersBarProps {
  /** Available players select options. */
  playersOptions: ISelectOption[];
  /** Disables select inputs. */
  disabled?: boolean;
  /** Sets same color to each input, otherwise component uses different colors for each input. */
  colorAll?: IPlayerColors | IOnOffColors;
  /** If true, input has on/off button for activate or deactivate input. */
  hasOnOff?: boolean;
  /** Tells if the input should be colored to green when it's on or to red when it's off. */
  onOffColored?: boolean;
  /** Bottom padding for the component. */
  paddingBottom?: CSSProperties['paddingBottom'];
}

const classNames = createClassNames('select-players-bar');

/** Select players controller for videomaps. */
export const SelectPlayersBar: FC<ISelectPlayersBarProps> = ({
  playersOptions,
  disabled,
  colorAll,
  hasOnOff = true,
  onOffColored,
  paddingBottom,
}) => {
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const selectedPlayersToSearch = Object.values(selectedPlayerItems)
      .map(({ selectedPlayer, isActive }) =>
        selectedPlayer?.value ? `${selectedPlayer.value}_${!!isActive}` : undefined,
      )
      .filter((item): item is string => !!item);

    setSearchParams(params => {
      if (selectedPlayersToSearch.length > 0) {
        params.set(IUrlParam.selectedPlayerItems, selectedPlayersToSearch.join(','));
      } else {
        params.delete(IUrlParam.selectedPlayerItems);
      }

      return params;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  const handleChangePlayer = (value: ISelectOption, itemKey: string, isOnOffClicked?: boolean) => {
    const getIsActiveValue = (): boolean | undefined => {
      if (!isOnOffClicked) return true;

      return !selectedPlayerItems[itemKey].isActive;
    };

    const isActive = value ? getIsActiveValue() : undefined;

    dispatch(
      setSelectedPlayerItems({
        selectedPlayerItems: {
          ...selectedPlayerItems[itemKey],
          selectedPlayer: value,
          isActive,
        },
        itemKey,
      }),
    );
  };

  const options = playersOptions.filter(
    option =>
      !Object.values(selectedPlayerItems).some(item => item.selectedPlayer?.value === option.value),
  );

  return (
    <div className={classNames()} style={{ paddingBottom }}>
      {Object.entries(selectedPlayerItems).map(([itemKey, itemValues]) => {
        const selected = playersOptions.find(
          option => itemValues.selectedPlayer?.value === option.value,
        );

        return (
          <SelectInput
            key={itemKey}
            onChange={newValue => handleChangePlayer(newValue, itemKey)}
            selected={selected}
            options={options}
            placeholder={ITranslationKeys.selectPlayer}
            variant='filter'
            color={colorAll ?? SELECT_PLAYERS_INPUT_COLORS[itemKey]}
            isOn={itemValues.isActive}
            onIsOnChange={value => handleChangePlayer(value, itemKey, true)}
            disabled={disabled}
            onOffColored={onOffColored}
            hasOnOff={hasOnOff}
            isClearable
            scrollable
          />
        );
      })}
    </div>
  );
};
