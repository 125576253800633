import { createSelector } from '@reduxjs/toolkit';

import { countAllGamesTotalToi, createGameEntity, filterGames } from '../../../utils';
import {
  selectGamelog,
  selectGames,
  selectGoalkeepersFilter,
  selectMainFilter,
  selectPasses,
  selectPlayers,
} from '../../selectors';
import {
  filteredShotsByEntitySelector,
  filteredShotsSelector,
  filteredShotsVideoMetricSelector,
} from '../../shots';

export const filteredGoalkeepersGamesSelector = createSelector(
  [selectMainFilter, selectGames],
  ({ selectedGames }, { games }) => filterGames(selectedGames, games.byId),
);

export const filteredGoalkeepersGamesByGoalkeeperSelector = createSelector(
  [selectGamelog, filteredGoalkeepersGamesSelector],
  (gamelog, filteredGoalkeepersGames) => {
    const matchIds = Object.values(gamelog.byId).map(game => game.id);
    return filteredGoalkeepersGames.filter(game => matchIds.includes(game.id));
  },
);

export const filteredGoalkeepersGamesTotalToiSelector = createSelector(
  [filteredGoalkeepersGamesByGoalkeeperSelector],
  filteredGames => countAllGamesTotalToi(filteredGames),
);

export const filteredGoalkeepersShotsBySavesSelector = createSelector(
  [selectGoalkeepersFilter, filteredShotsByEntitySelector],
  ({ saves }, filteredShotsByEntity) => {
    if (saves.value === 'all') return filteredShotsByEntity;

    if (saves.value === 'controlled') {
      return filteredShotsByEntity.filter(
        shot => shot.saveType === 'controlled' || shot.saveType === 'freeze',
      );
    }

    return filteredShotsByEntity.filter(shot => shot.saveType === saves.value);
  },
);

export const filteredGoalkeepersShotsByStickGripSelector = createSelector(
  [selectGoalkeepersFilter, selectPlayers, filteredShotsByEntitySelector],
  ({ stickGrip }, players, filteredShotsByEntity) => {
    const { byId, allIds } = players;

    if (!stickGrip || stickGrip.value === 'all') return filteredShotsByEntity;

    return filteredShotsByEntity.filter(shot => {
      const playerUuid = allIds.find(id => shot.playerId === id);
      if (!playerUuid) return false;

      const player = byId[playerUuid];
      if (!player) return false;

      return player.stick === stickGrip.value;
    });
  },
);

export const filteredGoalkeepersShotsByAssistSelector = createSelector(
  [selectGoalkeepersFilter, filteredShotsByEntitySelector],
  ({ shotAssist }, filteredShotsByEntity) => {
    if (shotAssist.value === 'all') return filteredShotsByEntity;

    if (shotAssist.value === 'nonsa') {
      return filteredShotsByEntity.filter(shot => !shot.assisted);
    }

    return filteredShotsByEntity.filter(shot => shot.assisted);
  },
);

export const filteredGoalkeepersShotsByAssistTypeSelector = createSelector(
  [selectGoalkeepersFilter, filteredShotsByEntitySelector, selectPasses],
  ({ shotAssistType }, filteredShotsByEntity, passesState) => {
    if (shotAssistType.value === 'all') return filteredShotsByEntity;

    if (shotAssistType.value === 'cr' || shotAssistType.value === 'bn') {
      const { passesAgainst } = passesState;

      return filteredShotsByEntity.filter(shot => {
        const pass = passesAgainst.find(
          pass =>
            shot.shotAssist &&
            pass.playerId === shot.shotAssist.player &&
            pass.x === shot.shotAssist.x &&
            pass.y === shot.shotAssist.y,
        );

        if (shotAssistType.value === 'cr') {
          return pass?.shot.crossIce === true;
        }

        return pass?.behindTheNet === true;
      });
    }

    return filteredShotsByEntity;
  },
);

export const filteredGoalkeepersShotsVideoMetricSelector = createSelector(
  [
    filteredShotsVideoMetricSelector,
    filteredGoalkeepersShotsBySavesSelector,
    filteredGoalkeepersShotsByStickGripSelector,
    filteredGoalkeepersShotsByAssistSelector,
    filteredGoalkeepersShotsByAssistTypeSelector,
  ],
  (
    filteredShots,
    filteredShotsBySaves,
    filteredShotsByStick,
    filteredShotsByAssist,
    filteredShotsByAssistType,
  ) => {
    const filteredShotsByAll = filteredShots.filter(
      shot =>
        filteredShotsBySaves.includes(shot) &&
        filteredShotsByStick.includes(shot) &&
        filteredShotsByAssist.includes(shot) &&
        filteredShotsByAssistType.includes(shot),
    );

    return filteredShotsByAll;
  },
);

export const filteredGoalkeepersShotsSelector = createSelector(
  [
    filteredShotsSelector,
    filteredGoalkeepersShotsBySavesSelector,
    filteredGoalkeepersShotsByStickGripSelector,
    filteredGoalkeepersShotsByAssistSelector,
  ],
  (filteredShots, filteredShotsBySaves, filteredShotsByStick, filteredGoalkeepersShotsByAssist) => {
    const filteredShotsByAll = filteredShots.filter(
      shot =>
        filteredShotsBySaves.includes(shot) &&
        filteredShotsByStick.includes(shot) &&
        filteredGoalkeepersShotsByAssist.includes(shot),
    );

    return filteredShotsByAll;
  },
);

export const filteredGoalkeepersShotsGameEntitySelector = createSelector(
  [selectMainFilter, filteredGoalkeepersShotsSelector],
  ({ selectedGames }, filteredShots) => createGameEntity(selectedGames, filteredShots),
);

export const filteredGoalkeepersFilterDataSelector = createSelector(
  [
    filteredGoalkeepersShotsSelector,
    filteredGoalkeepersShotsGameEntitySelector,
    filteredGoalkeepersGamesSelector,
    filteredGoalkeepersGamesTotalToiSelector,
  ],
  (
    filteredGoalkeepersShots,
    filteredGoalkeepersShotsGameEntity,
    filteredGoalkeepersGames,
    filteredGoalkeepersGamesTotalToi,
  ) => {
    return {
      filteredGoalkeepersShots,
      filteredGoalkeepersShotsGameEntity,
      filteredGoalkeepersGames,
      filteredGoalkeepersGamesTotalToi,
    };
  },
);
