import { SELECT_PLAYERS_INPUT_COLORS } from '../constants';
import { ITranslationKeys } from '../i18n/types';
import {
  ICoordinates,
  IGameRecord,
  INetZone,
  IPass,
  IPassesShot,
  IPlayerRecord,
  IPlaygroundBarBox,
  IPlaygroundShotOrPass,
  ISelectOption,
  ISelectedPlayersRecord,
  IShot,
  IShotDTO,
  ISimilarPlayerNameRecord,
  IStatToDisplay,
  ITeamRecord,
} from '../types';
import { getPlayerShortName, getValueOrUndefined } from './common.utils';
import { formatDateByLanguage } from './date.utils';
import { calculateShotOrPassPosition, roundNumberTo2Decimals } from './number.utils';

export const splitShotsByDanger = <T extends IShot | IPassesShot>(shots: T[]) => {
  const highDangerShots = shots.filter(shot => shot.shotDanger === 'hd');
  const mediumDangerShots = shots.filter(shot => shot.shotDanger === 'md');
  const lowDangerShots = shots.filter(shot => shot.shotDanger === 'ld');

  return {
    highDangerShots,
    mediumDangerShots,
    lowDangerShots,
  };
};

export const calculateShotsTotalXG = <T extends IShot | IPassesShot>(shots: T[]) =>
  shots.reduce<number>((acc, shot) => acc + shot.xG, 0);

/** Use for goalkeepers xGA count. */
export const calculateShotsTotalXGA = <T extends IShot>(shots: T[]) =>
  shots.reduce<number>((acc, shot) => acc + (1 - shot.xSv), 0);

export const filterGoals = <T extends IShot | IPassesShot>(shots: T[]) =>
  shots.filter(shot => shot.type === 'G');

export const prepareShotDangerStatsFromShots = <T extends IShot | IPassesShot>(
  shots: T[],
): IStatToDisplay[] => [
  {
    value: shots.length.toString(),
    label: ITranslationKeys.count,
  },
  {
    value: calculateShotsTotalXG(shots).toFixed(2),
    label: 'xG',
  },
  {
    value: filterGoals(shots).length.toString(),
    label: 'G',
  },
];

const convertShotAndPassToPlaygroundShared = <T extends IShot | IPass>(
  shotOrPass: T,
  teamRecord: ITeamRecord,
  selectedPlayerItems: ISelectedPlayersRecord,
  width: number = 760,
) => {
  const {
    videoId,
    videoTime,
    matchId,
    matchDate,
    playerId,
    time,
    homeTeamId,
    awayTeamId,
    score,
    realTime,
    x,
    y,
  } = shotOrPass;

  const homeTeamShortcut = teamRecord[homeTeamId]?.shortcut;
  const awayTeamShortcut = teamRecord[awayTeamId]?.shortcut;
  const position = calculateShotOrPassPosition(x, y, width);
  const selectedPlayerShotOrPass = Object.values(selectedPlayerItems).find(
    item => item.selectedPlayer?.value === playerId && item.isActive,
  );
  const color = selectedPlayerShotOrPass
    ? SELECT_PLAYERS_INPUT_COLORS[selectedPlayerShotOrPass.id]
    : undefined;

  return {
    videoInfo: {
      videoId,
      videoTime,
      matchId,
      matchDate,
      playerId,
      time,
      homeTeamId,
      awayTeamId,
      score,
      realTime,
    },
    homeTeamShortcut,
    awayTeamShortcut,
    position,
    color,
  };
};

export const convertShotToPlaygroundShot = (
  shot: IShot,
  teamRecord: ITeamRecord,
  playerRecord: IPlayerRecord,
  selectedPlayerItems: ISelectedPlayersRecord,
  width: number = 760,
): IPlaygroundShotOrPass => {
  const shotOrPassInfo = convertShotAndPassToPlaygroundShared(
    shot,
    teamRecord,
    selectedPlayerItems,
    width,
  );
  const player = playerRecord[shot.playerId];
  const shotPlayerName = `${player?.surname} ${player?.name}`;
  const assistPlayerName = shot.shotAssist
    ? `${playerRecord[shot.shotAssist.player]?.surname} ${playerRecord[shot.shotAssist.player]
        ?.name}`
    : undefined;

  return {
    ...shotOrPassInfo,
    xG: shot.xG,
    type: shot.type,
    emptyNet: shot.emptyNet,
    activeZone: shot.netZone,
    playerName: shotPlayerName,
    assistPlayerName,
  };
};

export const convertPassToPlaygroundPass = (
  pass: IPass,
  teamRecord: ITeamRecord,
  playerRecord: IPlayerRecord,
  selectedPlayerItems: ISelectedPlayersRecord,
): IPlaygroundShotOrPass => {
  const shotOrPassInfo = convertShotAndPassToPlaygroundShared(
    pass,
    teamRecord,
    selectedPlayerItems,
  );
  const shotPlayer = playerRecord[pass.shot.player];
  const assistPlayer = playerRecord[pass.playerId];
  const playerName = `${shotPlayer?.surname} ${shotPlayer?.name}`;
  const assistPlayerName = `${assistPlayer?.surname} ${assistPlayer?.name}`;

  return {
    ...shotOrPassInfo,
    xG: pass.shot?.xG ? roundNumberTo2Decimals(pass.shot.xG) : 0,
    type: pass.shot.type,
    emptyNet: pass.shot.emptyNet ?? false,
    activeZone: pass.shot.netZone,
    playerName,
    assistPlayerName,
    shot: pass.shot,
  };
};

export const getNetZonesShotsCount = (shots: IShot[]): Record<INetZone, number> => ({
  br: shots.filter(shot => shot.netZone === INetZone.br).length,
  bl: shots.filter(shot => shot.netZone === INetZone.bl).length,
  ce: shots.filter(shot => shot.netZone === INetZone.ce).length,
  fh: shots.filter(shot => shot.netZone === INetZone.fh).length,
  ul: shots.filter(shot => shot.netZone === INetZone.ul).length,
  ur: shots.filter(shot => shot.netZone === INetZone.ur).length,
});

export const getShotsDangerBoxes = <T extends IShot | IPassesShot>(shots: T[]) => {
  const { highDangerShots, mediumDangerShots, lowDangerShots } = splitShotsByDanger(shots);

  const boxHigh: IPlaygroundBarBox = {
    stats: prepareShotDangerStatsFromShots(highDangerShots),
    label: ITranslationKeys.high,
    color: 'green',
  };

  const boxMedium: IPlaygroundBarBox = {
    stats: prepareShotDangerStatsFromShots(mediumDangerShots),
    label: ITranslationKeys.medium,
    color: 'orange',
  };

  const boxLow: IPlaygroundBarBox = {
    stats: prepareShotDangerStatsFromShots(lowDangerShots),
    label: ITranslationKeys.low,
    color: 'red',
  };

  return {
    boxHigh,
    boxMedium,
    boxLow,
  };
};

export const mapShotsFromPasses = (passes: IPass[]) =>
  passes.map(pass => pass.shot).filter(shot => !!shot);

export const splitShotsByAttackType = (shots: IShot[]) => {
  const shotArray: IShot[][] = [];

  shotArray.push(shots.filter(shot => shot.forecheck));
  shotArray.push(shots.filter(shot => shot.rush));
  shotArray.push(shots.filter(shot => shot.long));
  shotArray.push(shots.filter(shot => shot.oddManRush));
  shotArray.push(shots.filter(shot => shot.afterFO));

  return shotArray;
};

export const splitShotsByDangerType = (shots: IShot[]) => {
  const shotArray: IShot[][] = [];

  shotArray.push(shots.filter(shot => shot.shotDanger === 'hd'));
  shotArray.push(shots.filter(shot => shot.shotDanger === 'md'));
  shotArray.push(shots.filter(shot => shot.shotDanger === 'ld'));

  return shotArray;
};

export const splitLastFiveShots = (shots: IShot[], options: ISelectOption[]) => {
  const shotArray: IShot[][] = [];

  options.forEach(option => {
    shotArray.push(shots.filter(shot => shot.matchId === option.value));
  });

  return shotArray;
};

export const splitShotsByPlayers = (shots: IShot[], players: ISelectedPlayersRecord) => {
  const activePlayers = Object.values(players).filter(player => player.isActive);
  if (activePlayers.length < 0) return [];

  const shotArray = activePlayers.reduce<IShot[][]>((acc, player) => {
    acc.push(shots.filter(shot => shot.playerId === player.selectedPlayer?.value));
    return acc;
  }, []);

  return shotArray;
};

export const computeResponsivePlaygroundSize = (
  width: number,
): { width: number; height: number } => {
  if (width > 1600) {
    return {
      width: 760,
      height: 582,
    };
  }

  if (width > 1399) {
    return {
      width: 530,
      height: 406,
    };
  }

  return {
    width: 462,
    height: 355,
  };
};

export const convertMatchAsOptionToLabeledSelectOption = (
  options: ISelectOption[],
  gamesById: IGameRecord,
  teamsById: ITeamRecord,
) => {
  const optionArray: ISelectOption[] = [];

  options.forEach(option => {
    const match = gamesById[option.value];
    const home = teamsById[match.homeTeamId]?.shortcut;
    const away = teamsById[match.awayTeamId]?.shortcut;
    const date = new Date(match.date);

    optionArray.push({
      value: option.value,
      label: `${home}-${away} ${formatDateByLanguage(date, 'P')}`,
    });
  });
  return optionArray;
};

export const convertPlayerRecordToOption = (
  playerById: IPlayerRecord,
  players: ISelectedPlayersRecord,
  similarPlayerNames: ISimilarPlayerNameRecord,
) => {
  const optionArray: ISelectOption[] = [];

  Object.values(players).forEach(player => {
    if (player.selectedPlayer?.value === undefined) return;

    const playerRecord = playerById[player.selectedPlayer.value];
    const playerShortName = getPlayerShortName(playerRecord, similarPlayerNames);

    optionArray.push({
      value: playerShortName,
      label: playerShortName,
    });
  });
  return optionArray;
};

export const filterToiFromShots = (shots: IShot[][], byId: IGameRecord) => {
  let toiArray: number[] = [];
  shots.forEach(shot => {
    const matches = Array.from(new Set(shot.map(s => s.matchId)));
    const toi = matches.reduce<number>((acc, match) => {
      const matchById = byId[match];
      if (matchById?.toi) {
        acc += matchById.toi;
      }

      return acc;
    }, 0);
    toiArray.push(toi);
  });

  return toiArray;
};

/**
 * Calculates horizontal position of point at heatmap.
 * @param y Coordinate of point at y axis.
 * @param width Total width of heatmap.
 * @returns Correct points position at percent.
 */
export const calculateHorizontalHeatmapPointPositionToPercent = (
  y: number,
  width: number = 760,
) => {
  const percent = ((y * -1 + 100) / 200) * 100;
  return (percent * width) / 100;
};

/**
 * Calculates vertical position of point at heatmap.
 * @param x Coordinate of point at x axis.
 * @param height Total height of heatmap.
 * @returns Correct points position at percent.
 */
export const calculateVerticalHeatmapPointPositionToPercent = (x: number, height: number = 582) => {
  const percent = 100 - ((x - 23) / 77) * 100;
  return (percent * height) / 100;
};

/**
 * Calculates coordinates of heatmap point at percent.
 * @param xPoint Coordinate of point at x axis.
 * @param yPoint Coordinate of point at y axis.
 * @param height Total height of heatmap.
 * @param width Total width of heatmap.
 * @returns Coordinates of heatmap point at percent.
 */
export const calculateHeatmapPointPositionToPercent = (
  coordinates: ICoordinates,
  height?: number,
  width?: number,
): ICoordinates => ({
  x: calculateVerticalHeatmapPointPositionToPercent(coordinates.x, height),
  y: calculateHorizontalHeatmapPointPositionToPercent(coordinates.y, width),
});

export const parseShot = (shot: IShotDTO): IShot => {
  const {
    'outbox-BLK': outboxBLK,
    xG,
    xSv,
    saveType,
    shotAssist,
    attackersCount,
    defendersCount,
    blocker,
    netZone,
    shotDanger,
    match,
    player,
    awayTeam,
    homeTeam,
    goalkeeper,
    ...restData
  } = shot;

  return {
    ...restData,
    matchId: match,
    playerId: player,
    goalkeeperId: goalkeeper,
    awayTeamId: awayTeam,
    homeTeamId: homeTeam,
    outboxBLK,
    xG: parseFloat(xG),
    xSv: parseFloat(xSv),
    saveType: getValueOrUndefined(saveType),
    shotAssist: getValueOrUndefined(shotAssist),
    attackersCount: getValueOrUndefined(attackersCount),
    defendersCount: getValueOrUndefined(defendersCount),
    blocker: getValueOrUndefined(blocker),
    netZone: getValueOrUndefined(netZone),
    shotDanger: shotDanger.toLowerCase(),
  };
};

export const parseShotsData = (data: IShotDTO[]): IShot[] =>
  data.map<IShot>(shotData => parseShot(shotData));
