import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { FilterWrapper, GameButtonListController, WidgetItemList } from '../../components';
import {
  filteredMainFilterDataSelector,
  getGames,
  selectCompetitionsDetail,
  selectMainFilter,
  setSelectedGames,
} from '../../features';
import { useLocationPaths } from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { IMainFilterForm, INavigationPageKeys } from '../../types';
import { parseUrlSelectedGames } from '../../utils';
import { ContentRoutesContainer } from '../ContentRoutesContainer';
import { usePageConfig } from './contents';

export const GamesPage = () => {
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedParts, selectedSeasons, selectedTeam, opponentsAtGamesPage } =
    useAppSelector(selectMainFilter);
  const { isAllCompetitionsDetailLoaded } = useAppSelector(selectCompetitionsDetail);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { activePage, activeTab, pathSegments } = useLocationPaths();
  const pageConfig = usePageConfig();
  const { formState, trigger, setValue } = useFormContext<IMainFilterForm>();

  useEffect(() => {
    if (isAllCompetitionsDetailLoaded && filteredParts.length > 0) {
      const competitionsUuids = filteredParts.map(competition => competition.id);
      const teamUuid =
        selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : undefined;
      const secondTeamUuid =
        opponentsAtGamesPage && opponentsAtGamesPage.value !== 'all'
          ? opponentsAtGamesPage.value
          : undefined;

      dispatch(
        getGames({
          competitionsUuids,
          teamUuid,
          secondTeamUuid,
        }),
      )
        .unwrap()
        .then(games => {
          if (activePage === INavigationPageKeys.games) {
            const selectedGames = parseUrlSelectedGames(
              searchParams,
              games.map(game => ({ value: game.id, label: game.id })),
            );

            if (selectedGames) {
              setValue('selectedGames', selectedGames);
              dispatch(setSelectedGames(selectedGames));
            }
          }
        });

      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllCompetitionsDetailLoaded,
    selectedSeasons,
    selectedParts,
    selectedTeam,
    opponentsAtGamesPage,
  ]);

  return (
    <div className='grow-and-flex-column' data-testid='games-page'>
      <form noValidate>
        <FilterWrapper
          isExtended={pathSegments.length > 1}
          additionalFilterElement={
            <GameButtonListController
              rules={{ required: { value: true, message: ITranslationKeys.pleaseSelectGame } }}
            />
          }
          errors={formState.errors}
          {...{ activeTab, pageConfig }}
        />
        {pathSegments.length === 1 && (
          <WidgetItemList config={pageConfig.tabsConfig} errors={formState.errors} />
        )}
      </form>
      <ContentRoutesContainer config={pageConfig.tabsConfig} activePage={activePage} />
    </div>
  );
};
