import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  GoalNet,
  GoalNetCountContent,
  Loading,
  Playground,
  PlaygroundShotsContent,
  ToggleSelect,
} from '../../../../components';
import {
  entityOptions,
  minimapComparisonOptions,
  shotCategoryOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeBaseOptions,
} from '../../../../constants';
import {
  filteredShotsDataSelector,
  filteredVideomapsFilterDataSelector,
  selectGames,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectShots,
  selectTeams,
  selectVideomapsFilter,
  setCompareBy,
  setSelectedGames,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useExportAsImage,
  useFetchShotsOrPasses,
  useHandleOnChange,
  useHandleOnSubmit,
  useShotAndPassEffects,
  useVideoCenter,
  useWindowSize,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { ISelectOption, IShot, IUrlParam } from '../../../../types';
import {
  calculateShotsTotalXG,
  changeUrlSearchParamsMultiselectInput,
  computeResponsivePlaygroundSize,
  convertMatchAsOptionToLabeledSelectOption,
  convertPlayerRecordToOption,
  convertShotToPlaygroundShot,
  createClassNames,
  filterGoals,
  filterToiFromShots,
  getGamesOptions,
  getNetZonesShotsCount,
  getShotsDangerBoxes,
  splitLastFiveShots,
  splitShotsByAttackType,
  splitShotsByDangerType,
  splitShotsByPlayers,
  transformObjectKeysToKebabCase,
} from '../../../../utils';
import './ShotsContent.styles.scss';

const classNames = createClassNames('videomaps-shots-content');

type IMinimapType = {
  [key: string]: {
    shots: IShot[][];
    options: ISelectOption[];
    toi: number[];
  };
};

export const ShotsContent = () => {
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { isLoading } = useAppSelector(selectShots);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { selectedPlayerItems, entity, shotCategory, compareBy } =
    useAppSelector(selectVideomapsFilter);
  const { shotDanger, shotLocation, shotType, gameActionType, netZone } =
    useAppSelector(selectMetricParamsFilter);
  const { filteredGamesTotalToi } = useAppSelector(filteredVideomapsFilterDataSelector);
  const {
    filteredShotsGameEntity,
    filteredShots,
    filteredShotsExceptGameAction,
    filteredShotsExceptDanger,
  } = useAppSelector(filteredShotsDataSelector);

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const lastFiveGamesOptions = useMemo(() => gamesOptions.slice(0, 5), [gamesOptions]);
  const netZonesShotsCount = useMemo(() => getNetZonesShotsCount(filteredShots), [filteredShots]);

  const { filteredShots: filteredShotsLastFiveGames } = useAppSelector(state =>
    filteredShotsDataSelector(state, lastFiveGamesOptions),
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const {
    onChangeShotCategory,
    onChangeEntity,
    onChangeShotLocation,
    onChangeShotDanger,
    onChangeShotType,
    onChangeGameActionType,
    onChangeNetZone,
  } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses, fetchShots } = useFetchShotsOrPasses();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredShotsGameEntity,
    ITranslationKeys.shots,
  );

  const isInitialMountRef = useRef(true);

  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(
    exportRef,
    `hokejLogic-videomapy-střely`,
    'png',
  );
  const minimapsExportRef = useRef<HTMLDivElement>(null);
  const { isExporting: areMinimapsExporting, exportImage: exportMinimapsImage } = useExportAsImage(
    minimapsExportRef,
    `hokejLogic-videomapy-střely-minimapy`,
    'png',
  );

  const windowSize = useWindowSize();

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchShots(values);
  }, isInitialMountRef);

  useShotAndPassEffects(handleOnSubmit, isInitialMountRef, isLoading);

  /**
   * Handle compareBy change and check if there are at least 2 players selected
   */
  useEffect(() => {
    if (compareBy.value === 'withTeammate') {
      const selectedPlayerCount = Object.values(selectedPlayerItems).filter(
        player => player.isActive,
      ).length;
      if (selectedPlayerCount < 2) {
        dispatch(setCompareBy(minimapComparisonOptions[0]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, IUrlParam.selectedGames, setSearchParams);
  };

  const handleCategoryChange = (option: ISelectOption) => {
    if (option.value === 'withTeammate') {
      const selectedPlayerCount = Object.values(selectedPlayerItems).filter(
        player => player.isActive,
      ).length;

      if (selectedPlayerCount < 2) {
        toast.warn(t(ITranslationKeys.selectAtLeastTwoPlayers), {
          toastId: 'PlayersNotPicked',
        });
        return;
      }
    }

    dispatch(setCompareBy(option));
  };

  const shotsByAttackType = splitShotsByAttackType(filteredShotsExceptGameAction);
  const shotsByDangerType = splitShotsByDangerType(filteredShotsExceptDanger);
  const shotsByPlayers = splitShotsByPlayers(filteredShots, selectedPlayerItems);
  const shotsByLastFiveGames = splitLastFiveShots(filteredShotsLastFiveGames, lastFiveGamesOptions);
  const filteredPlaygroundShots = filteredShots.map(shot => {
    const playgroundSize = computeResponsivePlaygroundSize(windowSize.width);
    return convertShotToPlaygroundShot(
      shot,
      teams.byId,
      players.byId,
      selectedPlayerItems,
      playgroundSize.width,
    );
  });
  const isValidFormation =
    Object.values(selectedPlayerItems).filter(player => player.isActive).length > 0;

  const minimapConfig: IMinimapType = {
    attackType: {
      shots: shotsByAttackType,
      options: shotGameActionTypeOptions.filter(option => option.value !== 'all'),
      toi: filterToiFromShots(shotsByAttackType, games.byId),
    },
    shotsDanger: {
      shots: shotsByDangerType,
      options: shotDangerOptions.filter(option => option.value !== 'all'),
      toi: filterToiFromShots(shotsByDangerType, games.byId),
    },
    withTeammate: {
      shots: shotsByPlayers,
      options: convertPlayerRecordToOption(
        players.byId,
        selectedPlayerItems,
        players.similarPlayerNames,
      ),
      toi: filterToiFromShots(shotsByPlayers, games.byId),
    },
    last5Games: {
      shots: shotsByLastFiveGames,
      options: convertMatchAsOptionToLabeledSelectOption(
        lastFiveGamesOptions,
        games.byId,
        teams.byId,
      ),
      toi: filterToiFromShots(shotsByLastFiveGames, games.byId),
    },
  };

  if (isLoading) return <Loading />;

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='videomaps-page__shots-content'>
      <div className={classNames('main')} ref={exportRef}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={filteredShotsGameEntity}
          selectedPlayerItems={selectedPlayerItems}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGamesTotalToi}
            c={filteredShots.length}
            xG={calculateShotsTotalXG(filteredShots)}
            g={filterGoals(filteredShots).length}
            boxes={getShotsDangerBoxes(filteredShots)}
            boxListHeading={ITranslationKeys.shotsDanger}
            isResponsive
            renderContent={() => (
              <PlaygroundShotsContent shots={filteredPlaygroundShots} onPlayClick={playVideos} />
            )}
            onPrintIconClick={exportImage}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.whoShoots} />
            <ToggleSelect
              selected={entity}
              options={entityOptions}
              onChange={newOption => onChangeEntity(newOption)}
              disabledOptions={!isValidFormation ? [entityOptions[0]] : undefined}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotCategory} />
            <ToggleSelect
              selected={shotCategory}
              options={shotCategoryOptions}
              onChange={newOption => onChangeShotCategory(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotLocation} />
            <ToggleSelect
              selected={shotLocation}
              options={shotLocationOptions}
              onChange={newOption => onChangeShotLocation(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotTypes} />
            <ToggleSelect
              selected={shotType}
              options={shotTypeBaseOptions}
              onChange={newOption => onChangeShotType(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotsDanger} />
            <ToggleSelect
              selected={shotDanger}
              options={shotDangerOptions}
              onChange={newOption => onChangeShotDanger(newOption)}
              twoColumns
            />
          </div>
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.attackType} />
            <ToggleSelect
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              onChange={newOption => onChangeGameActionType(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.netZones} />
            <GoalNet
              onZoneChange={newValue => onChangeNetZone(newValue, netZone)}
              activeZone={netZone}
              isClickable
              variant='black'
              content={<GoalNetCountContent netZonesShotsCount={netZonesShotsCount} />}
              disableContentPointerEvents
            />
          </div>
          <div className={classNames('main__form-box__save-button')}>
            <Button
              label={ITranslationKeys.saveMap}
              iconComponent={<DownloadIcon />}
              onClick={exportImage}
              disabled={isExporting}
            />
          </div>
        </div>
      </div>
      <div className={classNames('minimaps')} ref={minimapsExportRef}>
        <div className={classNames('minimaps__toggle-items')}>
          <Caption label={ITranslationKeys.filterBy} />
          <ToggleSelect
            selected={compareBy}
            options={minimapComparisonOptions}
            onChange={option => handleCategoryChange(option)}
          />
        </div>
        <div className={classNames('minimaps__content')}>
          {minimapConfig[compareBy.value].options.map((option, index) => {
            const actualMap = minimapConfig[compareBy.value];
            const shots = actualMap.shots[index];
            if (!shots) return null;

            const filteredMinimapPlaygroundShots = shots.map(shot =>
              convertShotToPlaygroundShot(shot, teams.byId, players.byId, selectedPlayerItems, 462),
            );

            return (
              <div
                key={option.value}
                className={classNames('minimaps__content__item', {
                  ...transformObjectKeysToKebabCase({ index }),
                })}
              >
                <span className={classNames('minimaps__content__item__title')}>
                  {t(option.label, { ...option.labelTranslationsOptions })}
                </span>
                <Playground
                  toi={actualMap.toi[index]}
                  c={shots.length}
                  xG={calculateShotsTotalXG(shots)}
                  g={filterGoals(shots).length}
                  boxes={getShotsDangerBoxes(shots)}
                  boxListHeading={ITranslationKeys.shotsDanger}
                  isSmall
                  renderContent={() => (
                    <PlaygroundShotsContent
                      shots={filteredMinimapPlaygroundShots}
                      onPlayClick={playVideos}
                      isSmall
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
        <div className={classNames('minimaps__save-button')}>
          <Button
            label={ITranslationKeys.saveMaps}
            iconComponent={<DownloadIcon />}
            onClick={exportMinimapsImage}
            disabled={areMinimapsExporting}
          />
        </div>
      </div>
    </div>
  );
};
