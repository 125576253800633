import { createSelector } from '@reduxjs/toolkit';

import { IGamesTableRecord, IPlayerWithTeamIdAndStats } from '../../../types';
import { selectedGameSelector } from '../../mainFilter';
import { selectGames, selectGoalkeepers, selectPlayers, selectTableFilter } from '../../selectors';

export const filteredGamesTablesSelector = createSelector(
  [(_, byId: IGamesTableRecord) => byId],
  byId => Object.values(byId).filter(player => player.id),
);

export const filteredGamesTablesFormationsSelector = createSelector(
  [selectGames, selectPlayers, selectedGameSelector],
  (gamesState, playersState, selectedGame) => {
    const { gamesTables } = gamesState;
    const { byId } = playersState;
    if (!selectedGame) return Object.values(gamesTables.formationsById);

    return Object.values(gamesTables.formationsById).map(formation => {
      const firstPlayerId = formation.playerIds[0];
      const firstPlayer = byId[firstPlayerId];
      const teamId = firstPlayer.teamIds.includes(selectedGame.homeTeamId)
        ? selectedGame.homeTeamId
        : selectedGame.awayTeamId;

      return {
        ...formation,
        teamId: teamId,
      };
    });
  },
);

export const filteredGamesTablesGoalkeepersSelector = createSelector(
  [selectGoalkeepers, selectedGameSelector, filteredGamesTablesSelector],
  (goalkeepers, selectedGame, filteredGamesTables) => {
    const { byId } = goalkeepers;
    if (!selectedGame) return filteredGamesTables;
    const { homeTeamId, awayTeamId } = selectedGame;

    return filteredGamesTables.map<IPlayerWithTeamIdAndStats>(game => {
      const goalkeeper = byId[game.id];
      const teamId = goalkeeper.teamIds.includes(homeTeamId) ? homeTeamId : awayTeamId;
      return {
        ...game,
        teamId: teamId,
      };
    });
  },
);

export const filteredGamesTablesGamePartSelector = createSelector(
  [selectPlayers, selectTableFilter, filteredGamesTablesSelector],
  (players, tableFilterState, filteredGamesTables) => {
    const { byId } = players;
    const { position } = tableFilterState;
    if (!position || position.value === 'all') return filteredGamesTables;

    return filteredGamesTables.filter(game => {
      const player = byId[game.id];
      return player.position === position.value;
    });
  },
);

export const filteredGamesTablesDataSelector = createSelector(
  [filteredGamesTablesGamePartSelector],
  filteredGamesTablesGamePart => {
    return filteredGamesTablesGamePart;
  },
);
