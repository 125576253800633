import {
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeOptions,
} from '../../constants';
import { IMetricParamsFilterState, INetZoneUnion, IUrlParam } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { parseUrlStaticSelectOption } from '../url.utils';

export const parseUrlMetricParamsFilterParams = (
  searchParams: URLSearchParams,
): Partial<IMetricParamsFilterState> => {
  const shotLocation = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shotLocation,
    shotLocationOptions,
  );
  const shotDanger = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shotDanger,
    shotDangerOptions,
  );
  const shotType = parseUrlStaticSelectOption(searchParams, IUrlParam.shotType, shotTypeOptions);
  const gameActionType = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.gameActionType,
    shotGameActionTypeOptions,
  );
  const netZone = searchParams.get(IUrlParam.netZone) as INetZoneUnion;

  return {
    shotLocation: shotLocation || getDefaultPredefinedOptionByValue(shotLocationOptions),
    shotDanger: shotDanger || getDefaultPredefinedOptionByValue(shotDangerOptions),
    shotType: shotType || getDefaultPredefinedOptionByValue(shotTypeOptions),
    gameActionType: gameActionType || getDefaultPredefinedOptionByValue(shotGameActionTypeOptions),
    netZone: netZone || undefined,
  };
};
