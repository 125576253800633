import { createSelector } from '@reduxjs/toolkit';

import { IGameEntity, ISelectOption, IShootout, IVideoInfo } from '../../types';
import { mapShootoutToVideoInfo } from '../../utils';
import {
  selectMainFilter,
  selectPlayers,
  selectShootout,
  selectShootoutFilter,
  selectVideomapsFilter,
} from '../selectors';

export const filteredShootoutsGamesSelector = createSelector(
  [selectMainFilter, selectShootout],
  ({ selectedGames }, shootoutsState) => {
    const { shootouts } = shootoutsState;
    if (!shootouts) return [];

    return shootouts.reduce<IShootout[]>((acc, shootout) => {
      const isSelected = selectedGames.some(game => game.value === shootout.matchId);
      if (isSelected) acc.push(shootout);

      return acc;
    }, []);
  },
);

const filterShootoutsBySelectedGames = (shootouts: IShootout[], selectedGames: ISelectOption[]) =>
  shootouts.filter(shootout => selectedGames.some(game => game.value === shootout.matchId));

const createGameEntityFromShootout = (
  selectedGames: ISelectOption[],
  shootouts: IShootout[],
  selectedTeam: ISelectOption,
  isGoalkeeper: boolean,
): IGameEntity[] => {
  const filteredGameEntities = selectedGames.map<IGameEntity>(game => {
    const filteredShootouts = isGoalkeeper
      ? shootouts.filter(
          shootout =>
            shootout.matchId === game.value && shootout.goalkeeper.teamId === selectedTeam.value,
        )
      : shootouts.filter(
          shootout =>
            shootout.matchId === game.value && shootout.skater.teamId === selectedTeam.value,
        );

    const videoInfoEntities = filteredShootouts.map<IVideoInfo>(shootout =>
      mapShootoutToVideoInfo(shootout, isGoalkeeper),
    );

    return {
      gameId: game.value,
      entities: videoInfoEntities,
    };
  });

  return filteredGameEntities;
};

export const filteredShootoutByPageSelector = createSelector(
  [selectMainFilter, selectShootout, selectVideomapsFilter],
  (mainFilterState, shootoutState, { teamId: videoTeamId }) => {
    const { selectedTeam } = mainFilterState;
    const { shootouts, shootoutPage } = shootoutState;

    if (!shootouts) return [];

    const selectedTeamId = videoTeamId || selectedTeam?.value;

    return shootouts.reduce<IShootout[]>((acc, shootout) => {
      if (shootoutPage === 'goalkeepers') {
        if (shootout.goalkeeper.teamId === selectedTeamId) acc.push(shootout);
      } else if (shootoutPage === 'videomaps') {
        if (shootout.skater.teamId === selectedTeamId) acc.push(shootout);
      }

      return acc;
    }, []);
  },
);

export const filteredShootoutByGoalkeeperSelector = createSelector(
  [selectMainFilter, filteredShootoutByPageSelector],
  (mainFilter, filteredShootouts) => {
    const { selectedGoalkeeper } = mainFilter;
    if (!selectedGoalkeeper) return [];
    return filteredShootouts.filter(
      shootout => shootout.goalkeeper.playerId === selectedGoalkeeper.value,
    );
  },
);

export const filteredShootoutByEndResultSelector = createSelector(
  [selectShootoutFilter, filteredShootoutByPageSelector],
  (shootoutFilter, filteredShootouts) => {
    const { endResult } = shootoutFilter;
    if (!endResult) return [];
    if (endResult.value === 'all') return filteredShootouts;
    return filteredShootouts.filter(shootout => shootout.goal === (endResult.value === 'goal'));
  },
);

export const filteredShootoutByEndTypeSelector = createSelector(
  [selectShootoutFilter, filteredShootoutByPageSelector],
  (shootoutFilter, filteredShootouts) => {
    const { endType } = shootoutFilter;
    if (!endType) return [];
    if (endType.value === 'all') return filteredShootouts;
    return filteredShootouts.filter(shootout => shootout.finish === endType.value);
  },
);

export const filteredShootoutByEndSideSelector = createSelector(
  [selectShootoutFilter, filteredShootoutByPageSelector],
  (shootoutFilter, filteredShootouts) => {
    const { endSide } = shootoutFilter;
    if (!endSide) return [];
    if (endSide.value === 'all') return filteredShootouts;
    return filteredShootouts.filter(shootout => shootout.stickSide === endSide.value);
  },
);

export const filteredShootoutByStickGripSelector = createSelector(
  [selectShootoutFilter, selectPlayers, filteredShootoutByPageSelector],
  (shootoutFilter, players, filteredShootouts) => {
    const { stickGrip } = shootoutFilter;

    if (!stickGrip) return [];
    if (stickGrip.value === 'all') return filteredShootouts;

    const filteredGrip = filteredShootouts.filter(shootout => {
      const player = players.byId[shootout.skater.playerId];
      if (!player) return false;
      return player.stick === stickGrip.value;
    });

    return filteredGrip;
  },
);

export const filteredShootoutsWithSelectedGoalkeeperSelector = createSelector(
  [
    filteredShootoutByGoalkeeperSelector,
    filteredShootoutByEndResultSelector,
    filteredShootoutByEndTypeSelector,
    filteredShootoutByEndSideSelector,
    filteredShootoutByStickGripSelector,
  ],
  (
    filteredShootoutByGoalkeeper,
    filteredShootoutByEndResult,
    filteredShootoutByEndType,
    filteredShootoutByEndSide,
    filteredShootoutByStickGrip,
  ) => {
    const filteredShootoutShotsByAll = filteredShootoutByGoalkeeper.filter(
      shot =>
        filteredShootoutByEndResult.includes(shot) &&
        filteredShootoutByEndType.includes(shot) &&
        filteredShootoutByEndSide.includes(shot) &&
        filteredShootoutByStickGrip.includes(shot),
    );

    return filteredShootoutShotsByAll;
  },
);

export const filteredShootoutsSelector = createSelector(
  [
    filteredShootoutByEndResultSelector,
    filteredShootoutByEndTypeSelector,
    filteredShootoutByEndSideSelector,
    filteredShootoutByStickGripSelector,
  ],
  (
    filteredShootoutByEndResult,
    filteredShootoutByEndType,
    filteredShootoutByEndSide,
    filteredShootoutByStickGrip,
  ) => {
    const filteredShootoutsByAll = filteredShootoutByEndResult.filter(
      shot =>
        filteredShootoutByEndResult.includes(shot) &&
        filteredShootoutByEndType.includes(shot) &&
        filteredShootoutByEndSide.includes(shot) &&
        filteredShootoutByStickGrip.includes(shot),
    );

    return filteredShootoutsByAll;
  },
);

export const filteredShootoutsBySelectedGamesSelector = createSelector(
  [selectMainFilter, filteredShootoutsSelector],
  ({ selectedGames }, filteredShootouts) =>
    filterShootoutsBySelectedGames(filteredShootouts, selectedGames),
);

export const filteredShootoutsBySelectedGamesWithSelectedGoalkeeperSelector = createSelector(
  [selectMainFilter, filteredShootoutsWithSelectedGoalkeeperSelector],
  ({ selectedGames }, filteredShootouts) =>
    filterShootoutsBySelectedGames(filteredShootouts, selectedGames),
);

export const filteredShootoutGameEntitySelector = createSelector(
  [selectMainFilter, selectShootout],
  (mainFilter, shootoutState) => {
    const { shootouts } = shootoutState;
    const { selectedTeam, selectedGoalkeeper, selectedGames } = mainFilter;

    if (!shootouts || !selectedTeam) return [];
    return createGameEntityFromShootout(
      selectedGames,
      shootouts,
      selectedTeam,
      !!selectedGoalkeeper,
    );
  },
);

export const filteredShootoutFilterDataSelector = createSelector(
  [
    filteredShootoutsSelector,
    filteredShootoutsWithSelectedGoalkeeperSelector,
    filteredShootoutsBySelectedGamesSelector,
    filteredShootoutsBySelectedGamesWithSelectedGoalkeeperSelector,
    filteredShootoutGameEntitySelector,
  ],
  (
    filteredShootouts,
    filteredShootoutsWithSelectedGoalkeeper,
    filteredShootoutsBySelectedGames,
    filteredShootoutsBySelectedGamesWithSelectedGoalkeeper,
    filteredShootoutGameEntity,
  ) => {
    return {
      filteredShootouts,
      filteredShootoutsWithSelectedGoalkeeper,
      filteredShootoutsBySelectedGames,
      filteredShootoutsBySelectedGamesWithSelectedGoalkeeper,
      filteredShootoutGameEntity,
    };
  },
);
