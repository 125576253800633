import { useAppDispatch } from '../../../app/hooks';
import {
  behindTheNetShotAssistTypeOption,
  controlledGoalkeeperSavesOption,
  crossIceShotAssistTypeOption,
  deflectedPlayerShotTypeOption,
  fromPassShotAssistOption,
  goalShotCategoryOption,
  onNetShotCategoryOption,
  oneTimerShotTypeOption,
  reboundsShotTypeOption,
  savesShotCategoryOption,
  screeningPlayerShotTypeOption,
  slotShotLocationOption,
  uncontrolledGoalkeeperSavesOption,
  withoutPassShotAssistOption,
} from '../../../constants';
import {
  setGoalkeeperSaves,
  setGoalkeeperShotAssist,
  setGoalkeeperShotAssistType,
  setNetZone,
  setShotCategory,
  setShotLocation,
  setShotType,
} from '../../../features';
import { IMetricWithDatasetMetric, INetZone } from '../../../types';

export const useSetShotmapFilter = () => {
  const dispatch = useAppDispatch();

  const setShotmapFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const subgroupId = stat.metric.metricSubgroup.id;
    const { mainMetric, parameters, isParameter, isSlot } = createMetricAndParamsForSlot(
      stat,
      subgroupId,
    );

    if (isSlot) {
      dispatch(setShotLocation(slotShotLocationOption));
    }

    if (isParameter) {
      parameters.forEach(parameter => {
        if (parameter === 'cn') {
          dispatch(setGoalkeeperSaves(controlledGoalkeeperSavesOption));
        } else if (parameter === 'un') {
          dispatch(setGoalkeeperSaves(uncontrolledGoalkeeperSavesOption));
        } else if (parameter === 'd') {
          dispatch(setShotType(deflectedPlayerShotTypeOption));
        } else if (parameter === 's') {
          dispatch(setShotType(screeningPlayerShotTypeOption));
        } else if (parameter === 'reb') {
          dispatch(setShotType(reboundsShotTypeOption));
        } else if (parameter === '1t') {
          dispatch(setShotType(oneTimerShotTypeOption));
        } else if (parameter === 'sa') {
          dispatch(setGoalkeeperShotAssist(fromPassShotAssistOption));
        } else if (parameter === 'nonsa') {
          dispatch(setGoalkeeperShotAssist(withoutPassShotAssistOption));
        } else if (parameter === 'cr') {
          dispatch(setGoalkeeperShotAssistType(crossIceShotAssistTypeOption));
        } else if (parameter === 'bn') {
          dispatch(setGoalkeeperShotAssistType(behindTheNetShotAssistTypeOption));
        } else if (parameter === 'br') {
          dispatch(setNetZone(INetZone.br));
        } else if (parameter === 'bl') {
          dispatch(setNetZone(INetZone.bl));
        } else if (parameter === 'ur') {
          dispatch(setNetZone(INetZone.ur));
        } else if (parameter === 'ul') {
          dispatch(setNetZone(INetZone.ul));
        } else if (parameter === 'fh') {
          dispatch(setNetZone(INetZone.fh));
        }
      });
    }

    if (mainMetric.startsWith('ga')) {
      dispatch(setShotCategory(goalShotCategoryOption));
    } else if (mainMetric.startsWith('sv')) {
      dispatch(setShotCategory(savesShotCategoryOption));
    } else {
      dispatch(setShotCategory(onNetShotCategoryOption));
    }
  };

  return { setShotmapFilterSetter };
};

const createMetricAndParamsForSlot = (stat: IMetricWithDatasetMetric, subgroupId: string) => {
  const parameters = stat.metric.id.split('.');
  const origin = parameters.pop()!;

  if (subgroupId === 'strely_na_branku_ze_slotu' || subgroupId === 'strely_ze_slotu_z_prvni') {
    return {
      mainMetric: origin.slice(1, origin.length),
      parameters,
      isParameter: true,
      isSlot: true,
    };
  }

  if (
    subgroupId === 'strely_ze_slotu_z_prihravky' ||
    subgroupId === 'strely_ze_slotu_z_krizne_prihravky' ||
    subgroupId === 'strely_ze_slotu_bez_prihravky'
  ) {
    const slotParameter = parameters.find(parameter => parameter.startsWith('s'));
    const paramsWithoutSlot = parameters.filter(parameter => !parameter.startsWith('s'));
    const slicedSlotParameter = slotParameter && slotParameter.slice(1, slotParameter.length);

    return {
      mainMetric: origin,
      parameters: slicedSlotParameter
        ? [...paramsWithoutSlot, slicedSlotParameter]
        : paramsWithoutSlot,
      isParameter: true,
      isSlot: true,
    };
  }

  return {
    mainMetric: origin,
    parameters,
    isParameter: stat.metric.id.includes('.'),
    isSlot: false,
  };
};
