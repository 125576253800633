import { MutableRefObject, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { DISABLED_TABS_ON_OFF, entityOptions } from '../constants';
import { selectGames, selectVideomapsFilter, setSelectedGames } from '../features';
import { useHandleOnChange, useRefetchContent } from '../hooks';
import { IHandleOnSubmitConfig, INavigationContentKeys } from '../types';
import { getGamesOptions, isAtLeastOnePlayerOn, parseUrlSelectedGames } from '../utils';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.shots);

export const useShotAndPassEffects = (
  handleOnSubmit: (config?: IHandleOnSubmitConfig) => Promise<void>,
  isInitialMountRef: MutableRefObject<boolean>,
  isLoading?: boolean,
) => {
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { onChangeEntity } = useHandleOnChange();

  useEffect(() => {
    if (Object.keys(games.byId).length > 0) {
      const gamesOptions = getGamesOptions(games.byId);
      const selectedPlayersItems = Object.values(selectedPlayerItems).filter(
        item => item.selectedPlayer,
      );
      const optionsToSelect =
        selectedPlayersItems.length > 1 ? [...gamesOptions] : [gamesOptions[0]];

      const selectedGames = parseUrlSelectedGames(searchParams, gamesOptions);

      dispatch(setSelectedGames(selectedGames || optionsToSelect));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games.byId]);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      handleOnSubmit();

      const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
      const optionsToSelect = isSomePlayerOn ? entityOptions[0] : entityOptions[1];

      onChangeEntity(optionsToSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  useRefetchContent({ handleOnSubmit, isLoading });
};
