import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import {
  selectCompetitionsDetail,
  selectGoalkeepers,
  selectPlayers,
  selectSeasons,
  selectTeams,
} from '../../features';
import { IMainFilterForm } from '../../types';
import {
  parseUrlDynamicFiltersParams,
  parseUrlFaceoffsFilterParams,
  parseUrlGoalkeepersFilterParams,
  parseUrlMetricParamsFilterParams,
  parseUrlShootoutFilterParams,
  parseUrlZonesFilterParams,
} from '../../utils';

export const useParseUrlFilterParams = () => {
  const seasons = useAppSelector(selectSeasons);
  const competitionsDetail = useAppSelector(selectCompetitionsDetail);
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const goalkeepers = useAppSelector(selectGoalkeepers);
  const [searchParams] = useSearchParams();

  const parseUrlFilterParams = (defaultData: IMainFilterForm) => {
    const { mainFilter, videomapsFilter } = parseUrlDynamicFiltersParams(
      searchParams,
      defaultData,
      seasons.byId,
      competitionsDetail.byId,
      teams.byId,
      players.byId,
      goalkeepers.byId,
    );
    const metricParamsFilter = parseUrlMetricParamsFilterParams(searchParams);
    const zonesFilter = parseUrlZonesFilterParams(searchParams);
    const faceoffsFilter = parseUrlFaceoffsFilterParams(searchParams);
    const shootoutFilter = parseUrlShootoutFilterParams(searchParams);
    const goalkeepersFilter = parseUrlGoalkeepersFilterParams(searchParams);

    return {
      mainFilter,
      videomapsFilter,
      metricParamsFilter,
      zonesFilter,
      faceoffsFilter,
      shootoutFilter,
      goalkeepersFilter,
    };
  };

  return parseUrlFilterParams;
};
