import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../../app/hooks';
import { selectAuth, selectGoalkeepers, selectPlayers } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  ISelectOption,
  IShiftVideoRecord,
  IVideo,
  IVideoControls,
  IVideoEdit,
  IVideoRecord,
} from '../../../types';
import { createClassNames, getVideoPlayer } from '../../../utils';
import { Modal } from '../../modalComponents';
import { VideoCenterControls } from '../VideoCenterControls';
import { VideoCenterHeader } from '../VideoCenterHeader';
import { VideoInfoHeader } from '../VideoInfoHeader';
import { VideocoachModal } from '../VideocoachModal';
import { ISharedVideoCenterSelectListProps } from '../types';
import './VideoCenterModal.styles.scss';
import { VideoPlayerFrame } from './VideoPlayerFrame';

export interface IVideoCenterModal {
  /** Is video center modal shown? */
  open: boolean;
  /** All filtered videos record object. */
  videoRecord: IVideoRecord | IShiftVideoRecord;
  /** All filtered videos record object without edits. */
  originalVideoRecord: IVideoRecord | IShiftVideoRecord;
  /** Video category key (shots, passes, etc). */
  categoryKey: ITranslationKeys | string;
  /** Options created from original video record. */
  options: ISelectOption[];
  /** Render function for video select list component. */
  renderList: (params: ISharedVideoCenterSelectListProps) => ReactNode;
  /** Function for closing video center modal. */
  handleClose: () => void;
  /** Function that updates videos record object. */
  handleSetVideoEdit: (edit: IVideoEdit) => void;
  /** Function that updates videos record object. */
  handleResetVideoEdit?: (videoUuid: string) => void;
  /** Is modal for goalkeepers? */
  isGoalkeeper?: boolean;
}

const classNames = createClassNames('video-center-modal');

/** VideoCenterModal UI component. */
export const VideoCenterModal: FC<IVideoCenterModal> = ({
  open,
  videoRecord,
  originalVideoRecord,
  categoryKey,
  options,
  renderList,
  handleClose,
  handleSetVideoEdit,
  handleResetVideoEdit,
  isGoalkeeper = false,
}) => {
  const { user } = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers);
  const goalkeepers = useAppSelector(selectGoalkeepers);

  const { t } = useTranslation();

  const [selectedVideos, setSelectedVideos] = useState<ISelectOption[]>([]);
  const [videoControls, setVideoControls] = useState<IVideoControls>({
    videoTime: 0,
    cutBefore: 5,
    cutAfter: 5,
  });
  const [video, setVideo] = useState<IVideo>();
  const [videocoachModal, setVideocoachModal] = useState<boolean>(false);
  const [videocoachVideo, setVideocoachVideo] = useState<IVideo>();
  /** Video player states. */
  const [videoPlayerHtml, setVideoPlayerHtml] = useState<string>();
  const [iframeKey, setIframeKey] = useState<number>(0);

  useEffect(() => {
    if (video) {
      handleSetVideoEdit({
        videoUuid: video.videoUuid,
        videoTime: videoControls.videoTime,
        cutBefore: videoControls.cutBefore,
        cutAfter: videoControls.cutAfter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoControls, video]);

  const handleSelect = (selected: ISelectOption[]) => setSelectedVideos(selected);

  const handlePlay = (videoRecordId: string) => {
    const current = videoRecord[videoRecordId];
    setVideo(current);
    setVideoControls({
      videoTime: current.videoTime,
      cutBefore: current.cutBefore,
      cutAfter: current.cutAfter,
    });
    handleLoadVideoPlayer(current.matchId, current.videoTime - current.cutBefore);
  };

  const onClose = () => {
    setSelectedVideos([]);
    setVideo(undefined);
    handleClose();
  };

  const handleLoadVideoPlayer = (matchId: string, videoTime: number) => {
    setIframeKey(prevState => prevState + 1);
    setVideoPlayerHtml(undefined);

    getVideoPlayer({
      matchUuid: matchId,
      seekTime: videoTime,
    })
      .then(res => {
        setVideoPlayerHtml(res.data);
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.videoLoadFailed), {
          toastId: ITranslationKeys.videoLoadFailed,
        });
        console.error('[VideoCenterModal]: Video player load error:', error);
      });
  };

  const openVideocoach = (videoRecordId: string) => {
    setVideocoachModal(true);
    setVideocoachVideo(videoRecord[videoRecordId]);
  };

  const closeVideocoach = () => {
    setVideocoachModal(false);
    setVideocoachVideo(undefined);
  };

  const onCroppedTime = (videoRecordId: string) => {
    handleResetVideoEdit?.(videoRecordId);

    if (videoRecordId === video?.videoUuid) {
      setVideoControls({
        videoTime: originalVideoRecord[videoRecordId].videoTime,
        cutBefore: 5,
        cutAfter: 5,
      });
    }
  };

  const goalkeeperRecord = isGoalkeeper ? goalkeepers.byId : undefined;

  return (
    <Modal open={open} size='small'>
      <div className={classNames()}>
        <VideoCenterHeader video={video} categoryKey={categoryKey} onClose={onClose} />
        <div className={classNames('content')}>
          {renderList({
            categoryKey,
            selected: selectedVideos,
            onChange: handleSelect,
            playerRecord: players.byId,
            goalkeeperRecord,
            onPlay: handlePlay,
            onVideocoachNote: user?.teamId ? openVideocoach : undefined,
            onCroppedTime,
            options,
          })}
          <div className={classNames('video-container')}>
            {video && (
              <>
                <VideoInfoHeader
                  player={players.byId[video.playerId]}
                  time={video.time}
                  date={new Date(video.matchDate)}
                  onClose={() => {
                    setVideo(undefined);
                    setVideoPlayerHtml(undefined);
                  }}
                />
                <div className={classNames('video')}>
                  <VideoPlayerFrame videoPlayerHtml={videoPlayerHtml} iframeKey={iframeKey} />
                  <VideoCenterControls
                    controls={videoControls}
                    setControls={setVideoControls}
                    onVideocoachNote={
                      user?.teamId ? () => openVideocoach(video.videoUuid) : undefined
                    }
                    onPlay={() => {
                      const current = videoRecord[video.videoUuid];
                      handleLoadVideoPlayer(current.matchId, current.videoTime - current.cutBefore);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {videocoachVideo && (
          <VideocoachModal
            open={videocoachModal}
            onClose={closeVideocoach}
            video={videocoachVideo}
          />
        )}
      </div>
    </Modal>
  );
};
