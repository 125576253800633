import { createSelector } from '@reduxjs/toolkit';

import { IGame, ISelectOption, IShiftVideo, IShiftVideoRecord } from '../../types';
import { selectedGameSelector } from '../mainFilter';
import { selectVideoShifts } from '../selectors';

const filterByEvents = (
  shiftVideos: IShiftVideo[],
  selectedShiftEvents: ISelectOption,
  selectedGame: IGame | null | undefined,
  playerId: string | undefined,
) => {
  if (selectedShiftEvents.value === 'team') {
    return shiftVideos.filter(
      shift => !shift.eventInfo || shift.eventInfo.teamId === selectedGame?.homeTeamId,
    );
  }

  if (selectedShiftEvents.value === 'opponent') {
    return shiftVideos.filter(
      shift => !shift.eventInfo || shift.eventInfo.teamId === selectedGame?.awayTeamId,
    );
  }

  return shiftVideos.filter(shift => shift.playerId === playerId);
};

const filteredShiftVideoRecordByEventsSelector = createSelector(
  [selectVideoShifts, selectedGameSelector],
  (videoShiftsState, selectedGame) => {
    const { shifts, selectedShiftEvents, playerId } = videoShiftsState;

    if (!selectedShiftEvents || selectedShiftEvents.value === 'all') return shifts;

    const shiftVideos = Object.values(shifts);

    const shiftVideoRecord = filterByEvents(
      shiftVideos,
      selectedShiftEvents,
      selectedGame,
      playerId,
    ).reduce<IShiftVideoRecord>(
      (acc, shift) => ({
        ...acc,
        [shift.videoUuid]: shift,
      }),
      {},
    );

    return shiftVideoRecord;
  },
);

export const shiftVideoRecordWithEditsSelector = createSelector(
  [selectVideoShifts, filteredShiftVideoRecordByEventsSelector],
  (videoShiftsState, filteredShifts) => {
    const { edits } = videoShiftsState;

    const videoRecord = edits.reduce<IShiftVideoRecord>((acc, { videoUuid, ...restEdit }) => {
      const editedRecord: IShiftVideoRecord = {
        ...acc,
        [videoUuid]: {
          ...filteredShifts[videoUuid],
          ...restEdit,
        },
      };

      return editedRecord;
    }, filteredShifts);

    return {
      videoRecord,
      edits,
    };
  },
);
