import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredFaceoffsSelector,
  filteredGoalkeepersShotsVideoMetricSelector,
  filteredPassesVideoMetricSelector,
  filteredShootoutsSelector,
  filteredShotsVideoMetricSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  selectMainFilter,
  selectVideoCenter,
  selectVideomapsFilter,
  setMetricVideoGameIds,
  setVideoRecord,
} from '../../features';
import {
  IFaceoff,
  IMetricVideoLinkKeys,
  IPass,
  ISelectOption,
  IShot,
  IZoneEntity,
} from '../../types';
import {
  createVideoRecordFromArray,
  filterUniqueGameIds,
  mapShootoutToVideoInfo,
} from '../../utils';

export const useSetVideoRecordAtMetricDataLoad = () => {
  const filteredShots = useAppSelector(filteredShotsVideoMetricSelector);
  const filteredPasses = useAppSelector(filteredPassesVideoMetricSelector);
  const filteredFaceoffs = useAppSelector(filteredFaceoffsSelector);
  const filteredShootouts = useAppSelector(filteredShootoutsSelector);
  const { metricVideoLinkId } = useAppSelector(selectVideoCenter);
  const { entity, entityId, filterByProp } = useAppSelector(selectVideomapsFilter);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);
  const filteredGoalkeepersShots = useAppSelector(filteredGoalkeepersShotsVideoMetricSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (metricVideoLinkId === IMetricVideoLinkKeys.shots) {
      const shots = filterShotsOrEntries(
        filteredShots,
        entity,
        filterByProp,
        entityId,
        selectedGames,
      );
      const gameIds = filterUniqueGameIds(shots);
      console.log('shots', shots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shots)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.passes) {
      const passes = filterPassesOrExits(filteredPasses, entity, entityId, selectedGames);
      const gameIds = filterUniqueGameIds(passes);
      console.log('passes', passes);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(passes)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.faceoffs) {
      const faceoffs = filterFaceoffs(filteredFaceoffs, entity, selectedGames);
      const gameIds = filterUniqueGameIds(faceoffs);
      console.log('faceoffs', faceoffs);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(faceoffs)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.entries) {
      const entries = filterShotsOrEntries(
        zoneEntries,
        entity,
        filterByProp,
        entityId,
        selectedGames,
      );
      const gameIds = filterUniqueGameIds(entries);
      console.log('entries', entries);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(entries)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.exits) {
      const exits = filterPassesOrExits(zoneExits, entity, entityId, selectedGames);
      const gameIds = filterUniqueGameIds(exits);
      console.log('exits', exits);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(exits)));
    }

    if (
      metricVideoLinkId === IMetricVideoLinkKeys.shootouts ||
      metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts
    ) {
      const isTeam = entity.value === 'team';
      const isGoalkeeper = metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts;
      const filteredShootoutsByPlayer = isTeam
        ? filteredShootouts
        : filteredShootouts.filter(shootout =>
            isGoalkeeper
              ? shootout.goalkeeper.playerId === entityId
              : shootout.skater.playerId === entityId,
          );
      const filteredShootoutsByGames =
        selectedGames.length > 0
          ? filteredShootoutsByPlayer.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredShootoutsByPlayer;
      const shootouts = filteredShootoutsByGames.map(shootout => mapShootoutToVideoInfo(shootout));

      const gameIds = filterUniqueGameIds(filteredShootoutsByGames);
      console.log('filteredShootouts', filteredShootoutsByGames);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shootouts)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const goalkeeperShots =
        selectedGames.length > 0
          ? filteredGoalkeepersShots.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredGoalkeepersShots;
      const gameIds = filterUniqueGameIds(goalkeeperShots);
      console.log('goalkeeperShots', goalkeeperShots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(goalkeeperShots)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredShots,
    filteredPasses,
    filteredFaceoffs,
    filteredShootouts,
    zoneEntries,
    zoneExits,
    filteredGoalkeepersShots,
  ]);
};

const filterShotsOrEntries = <T extends IShot | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  filterByProp: string | undefined,
  entityId: string | undefined,
  selectedGames: ISelectOption[],
) => {
  if ((entity.value === 'team' || entity.value === 'opponentTeam') && selectedGames.length > 0) {
    return items.filter(item => selectedGames.some(game => game.value === item.matchId));
  }

  const itemsByEntityId = items.filter(item => item[filterByProp as keyof T] === entityId);

  const filteredItems =
    entity.value === 'player'
      ? itemsByEntityId
      : filterByProp === 'blocker'
      ? itemsByEntityId
      : items;

  return filteredItems;
};

const filterPassesOrExits = <T extends IPass | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  entityId: string | undefined,
  selectedGames: ISelectOption[],
) => {
  if ((entity.value === 'team' || entity.value === 'opponentTeam') && selectedGames.length > 0) {
    return items.filter(item => selectedGames.some(game => game.value === item.matchId));
  }

  return entity.value === 'player' ? items.filter(item => item.playerId === entityId) : items;
};

const filterFaceoffs = (
  faceoffs: IFaceoff[],
  entity: ISelectOption,
  selectedGames: ISelectOption[],
) => {
  if ((entity.value === 'team' || entity.value === 'opponentTeam') && selectedGames.length > 0) {
    return faceoffs.filter(faceoff => selectedGames.some(game => game.value === faceoff.matchId));
  }

  return faceoffs;
};
