import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES, teamAllOption } from '../constants';
import {
  resetDataSettingsFilterState,
  resetFaceoffsFilter,
  resetGoalkeepersFilter,
  resetMainFilter,
  resetMetricParamsFilter,
  resetShootoutFilter,
  resetTableFilterState,
  resetVideomapsFilter,
  resetZonesFilter,
  selectCompetitionsDetail,
  selectNavigation,
  selectSeasons,
} from '../features';
import { IMainFilterForm } from '../types';
import { getLastSeason, getPartOption, getSeasonOption } from '../utils';
import { useParseUrlFilterParams } from './urlFilters';
import { useLocationPaths } from './useLocationPaths';

/**
 * Hook that resets filter forms when the page is reloaded or changed. It resets hook form and redux state.
 * @param methods Main filter's react hook form methods
 */
export const useResetFormsOnPageReloadOrChange = (methods: UseFormReturn<IMainFilterForm>) => {
  const seasons = useAppSelector(selectSeasons);
  const competitionsDetail = useAppSelector(selectCompetitionsDetail);
  const { isLoadingAllInitialData } = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();

  const parseUrlFilterParams = useParseUrlFilterParams();
  const { activePage } = useLocationPaths();

  useEffect(() => {
    if (competitionsDetail.isAllCompetitionsDetailLoaded && !isLoadingAllInitialData) {
      const lastSeason = getLastSeason(seasons.byId);
      const lastSeasonOption = getSeasonOption(lastSeason);
      const firstCompetitionDetail =
        Object.values(competitionsDetail.byId).find(competition => competition.part === 'base') ||
        competitionsDetail.byId[competitionsDetail.allIds[0]];
      const firstPartOption = getPartOption(firstCompetitionDetail);

      const defaultData: IMainFilterForm = {
        selectedSeasons: [lastSeasonOption],
        selectedParts: [firstPartOption],
        selectedTeam: teamAllOption,
        opponentsAtGamesPage: teamAllOption,
        ...DEFAULT_MAIN_FILTER_FORM_STANDARD_VALUES,
      };

      const {
        mainFilter,
        videomapsFilter,
        metricParamsFilter,
        zonesFilter,
        faceoffsFilter,
        shootoutFilter,
        goalkeepersFilter,
      } = parseUrlFilterParams(defaultData);

      dispatch(resetMainFilter(mainFilter));
      methods.reset(mainFilter);

      dispatch(resetVideomapsFilter(videomapsFilter));
      dispatch(resetMetricParamsFilter(metricParamsFilter));
      dispatch(resetZonesFilter(zonesFilter));
      dispatch(resetFaceoffsFilter(faceoffsFilter));
      dispatch(resetShootoutFilter(shootoutFilter));
      dispatch(resetGoalkeepersFilter(goalkeepersFilter));
      dispatch(resetTableFilterState());
      dispatch(resetDataSettingsFilterState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, isLoadingAllInitialData]);
};
