import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { IMainFilterForm, IMainSubmittedValues, ISelectOption } from '../types';

/** Returns memoized and correctly typed defaul values (= last submitted values) of main form.*/
export const useMainSubmittedValues = () => {
  const {
    formState: { defaultValues },
  } = useFormContext<IMainFilterForm>();

  const submittedValues: IMainSubmittedValues = useMemo(
    () => ({
      playerOption:
        defaultValues && defaultValues.selectedPlayer
          ? (defaultValues.selectedPlayer as ISelectOption)
          : undefined,
      goalkeeperOption:
        defaultValues && defaultValues.selectedGoalkeeper
          ? (defaultValues.selectedGoalkeeper as ISelectOption)
          : undefined,
      teamOption:
        defaultValues && defaultValues.selectedTeam
          ? (defaultValues.selectedTeam as ISelectOption)
          : undefined,
    }),
    [defaultValues],
  );

  return submittedValues;
};
