import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../app/hooks';
import { METRIC_VIDEO_LINK_CONFIG_RECORD } from '../../../constants';
import {
  selectFaceoffs,
  selectGames,
  selectMainFilter,
  selectPasses,
  selectShootout,
  selectShots,
  selectTeams,
  selectVideoCenter,
  selectVideomapsFilter,
  selectZones,
  videoRecordWithEditsSelector,
} from '../../../features';
import {
  useMultiselect,
  useNormalizeUrlFilterParams,
  useSetVideoRecordAtMetricDataLoad,
} from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { DownloadIcon } from '../../../icons';
import { INavigationPageKeys, IVideoRecord } from '../../../types';
import {
  combineSearchAndFilterParams,
  createClassNames,
  getMetricParametersInfo,
  normalizeUrlWithFilterParams,
  prepareDownloadVideo,
} from '../../../utils';
import { Button } from '../../Button';
import { CheckboxButton } from '../../CheckboxButton';
import { Loading } from '../../Loading';
import { VideoCenterSelectItemContent } from '../VideoCenterSelectItemContent';
import { useOnDownloadVideos } from '../hooks';
import { ISharedVideoCenterSelectListProps } from '../types';
import './VideoCenterSelectListMetric.styles.scss';

export type IVideoCenterSelectListMetric<TOption = undefined> =
  ISharedVideoCenterSelectListProps<TOption> & {
    /** Object with videos data. */
    videosRecord: IVideoRecord;
  };

const classNames = createClassNames('video-center-select-list-metric');

/** Renders video center list and allows to multi select videos, select all videos or download videos. */
export const VideoCenterSelectListMetric = <TOption extends any = undefined>({
  options,
  selected,
  onChange,
  hasSelectAllOption = true,
  color,
  shouldCheckFullBody = false,
  categoryKey,
  videosRecord,
  playerRecord,
  goalkeeperRecord,
  onPlay,
  onVideocoachNote,
  onCroppedTime,
}: IVideoCenterSelectListMetric<TOption>) => {
  const teams = useAppSelector(selectTeams);
  const { edits } = useAppSelector(videoRecordWithEditsSelector);
  const { isLoading: isLoadingShots } = useAppSelector(selectShots);
  const { isLoading: isLoadingPasses } = useAppSelector(selectPasses);
  const { isLoading: isLoadingFaceoffs } = useAppSelector(selectFaceoffs);
  const { isLoading: isLoadingShootout } = useAppSelector(selectShootout);
  const { games } = useAppSelector(selectGames);
  const { areZonesLoading } = useAppSelector(selectZones);
  const { metricVideoLinkId, datasetMetric } = useAppSelector(selectVideoCenter);
  const { countOfPlayers } = useAppSelector(selectMainFilter);
  const { entityId } = useAppSelector(selectVideomapsFilter);

  useSetVideoRecordAtMetricDataLoad();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { downloading, onDownload } = useOnDownloadVideos();
  const normalizeUrlFilterParams = useNormalizeUrlFilterParams();

  const playerOrGoalkeeperRecord = goalkeeperRecord || playerRecord;

  const handleDownload = () => {
    const downloadVideos = [
      ...selected.map(option =>
        prepareDownloadVideo(
          videosRecord[option.value],
          playerOrGoalkeeperRecord,
          teams.byId,
          categoryKey,
        ),
      ),
    ];

    onDownload(downloadVideos);
  };

  const navigateToVideomap = () => {
    const contentUrl = metricVideoLinkId
      ? METRIC_VIDEO_LINK_CONFIG_RECORD[metricVideoLinkId].contentKey
      : undefined;
    const filterParams = normalizeUrlFilterParams(!!goalkeeperRecord, contentUrl);
    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);

    const pageUrl = goalkeeperRecord
      ? INavigationPageKeys.goalkeepers
      : INavigationPageKeys.videomaps;
    const pagesUrl = contentUrl ? `/${pageUrl}/${contentUrl}` : `/${pageUrl}`;

    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  const { selectedAll, onChangeToggle, onChangeToggleSelectAll } = useMultiselect<TOption>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  const isLoading =
    isLoadingShots ||
    isLoadingPasses ||
    isLoadingFaceoffs ||
    isLoadingShootout ||
    games.isLoading ||
    areZonesLoading;
  const player = entityId ? playerOrGoalkeeperRecord[entityId] : undefined;

  const parametersInfo = datasetMetric ? getMetricParametersInfo(datasetMetric) : undefined;

  return (
    <div className={classNames()}>
      {player && (
        <h2 className={classNames('label')}>
          #{player.jersey} {player.surname} {player.name[0]}.
        </h2>
      )}
      <div className={classNames('header-container')}>
        <div className={classNames('header-container__item')}>
          <strong>{t(ITranslationKeys.category)}:</strong>
          {metricVideoLinkId
            ? t(METRIC_VIDEO_LINK_CONFIG_RECORD[metricVideoLinkId].translationKey)
            : t(ITranslationKeys.all)}
        </div>
        <div className={classNames('header-container__item')}>
          <strong>{t(ITranslationKeys.countOfPlayers)}:</strong>
          {countOfPlayers?.label && t(countOfPlayers?.label)}
        </div>
        {parametersInfo?.map(parameter => (
          <div className={classNames('header-container__item')} key={parameter.id}>
            <strong>{t(parameter.paramGroupLabel)}:</strong>
            {t(parameter.label)}
          </div>
        ))}
      </div>
      <hr />
      {hasSelectAllOption && (
        <div className={classNames('all-button')}>
          <CheckboxButton checked={selectedAll} onClick={onChangeToggleSelectAll}>
            <p>{t(ITranslationKeys.selectAll)}</p>
          </CheckboxButton>
        </div>
      )}
      <div className={classNames('options-box')}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={classNames('options-box__options')}>
            {options
              .sort((a, b) => {
                const videoA = videosRecord[a.value];
                const videoB = videosRecord[b.value];

                const dateA = new Date(videoA.matchDate).getTime();
                const dateB = new Date(videoB.matchDate).getTime();

                if (dateA > dateB) {
                  return 1;
                } else if (dateA < dateB) {
                  return -1;
                }

                if (videoA.matchId > videoB.matchId) {
                  return 1;
                } else if (videoA.matchId < videoB.matchId) {
                  return -1;
                }

                return videoA.videoTime - videoB.videoTime;
              })
              .map(option => {
                const isSelected = selected.some(item => item.value === option.value);
                const isEdited = edits.some(item => item.videoUuid === option.value);
                const video = videosRecord[option.value];

                if (!video) return null;

                const player = playerRecord[video.playerId];

                return (
                  <CheckboxButton
                    key={option.value}
                    checked={isSelected}
                    onClick={() => onChangeToggle(option)}
                    {...{ color, shouldCheckFullBody }}
                  >
                    <VideoCenterSelectItemContent
                      key={option.value}
                      {...{ ...player, ...video }}
                      homeTeam={
                        video.homeTeamId ? teams.byId[video.homeTeamId].shortcut : undefined
                      }
                      awayTeam={
                        video.awayTeamId ? teams.byId[video.awayTeamId].shortcut : undefined
                      }
                      isEdited={isEdited}
                      onPlay={() => onPlay(option.value)}
                      onVideocoachNote={
                        onVideocoachNote ? () => onVideocoachNote(option.value) : undefined
                      }
                      onCroppedTime={onCroppedTime ? () => onCroppedTime(option.value) : undefined}
                      shouldPlayImmediately={options.length === 1}
                    />
                  </CheckboxButton>
                );
              })}
          </div>
        )}
      </div>
      <div className={classNames('list-buttons')}>
        <Button
          label={ITranslationKeys.downloadSelectedVideos}
          disabled={selected.length === 0 || downloading}
          iconComponent={<DownloadIcon />}
          onClick={handleDownload}
        />
        <Button
          label={ITranslationKeys.navigateToVideomap}
          iconComponent={<DownloadIcon className={classNames('list-buttons__navigate-icon')} />}
          onClick={navigateToVideomap}
        />
      </div>
    </div>
  );
};
