import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../app/hooks';
import {
  setDumpInOutResult,
  setEntity,
  setGameActionType,
  setNetZone,
  setShootoutEndResult,
  setShootoutEndSide,
  setShootoutEndType,
  setShotCategory,
  setShotDanger,
  setShotLocation,
  setShotType,
  setZoneCategory,
  setZoneFinish,
  setZoneSuccess,
} from '../features';
import { INetZoneUnion, ISelectOption, IUrlParam } from '../types';
import { useSetUrlSearchParams } from './urlFilters';

export const useHandleOnChange = () => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();
  const { setUrlSearchParams } = useSetUrlSearchParams();

  const onChangeShotCategory = (option: ISelectOption) => {
    dispatch(setShotCategory(option));
    setUrlSearchParams(IUrlParam.shotCategory, option.value);
  };

  const onChangeEntity = (option: ISelectOption) => {
    dispatch(setEntity(option));
    setUrlSearchParams(IUrlParam.entity, option.value, 'team');
  };

  const onChangeShotLocation = (option: ISelectOption) => {
    dispatch(setShotLocation(option));
    setUrlSearchParams(IUrlParam.shotLocation, option.value);
  };

  const onChangeShotDanger = (option: ISelectOption) => {
    dispatch(setShotDanger(option));
    setUrlSearchParams(IUrlParam.shotDanger, option.value);
  };

  const onChangeShotType = (option: ISelectOption) => {
    dispatch(setShotType(option));
    setUrlSearchParams(IUrlParam.shotType, option.value);
  };

  const onChangeGameActionType = (option: ISelectOption) => {
    dispatch(setGameActionType(option));
    setUrlSearchParams(IUrlParam.gameActionType, option.value);
  };

  const onChangeNetZone = (newValue: INetZoneUnion, previousValue: INetZoneUnion | undefined) => {
    if (newValue === previousValue) {
      dispatch(setNetZone(undefined));
      setSearchParams(params => {
        params.delete(IUrlParam.netZone);
        return params;
      });
    } else {
      dispatch(setNetZone(newValue));
      setSearchParams(params => {
        params.set(IUrlParam.netZone, newValue);
        return params;
      });
    }
  };

  const onChangeZoneCategory = (option: ISelectOption) => {
    dispatch(setZoneCategory(option));
    setUrlSearchParams(IUrlParam.zoneCategory, option.value, 'controlled');
  };

  const onChangeZoneSuccess = (option: ISelectOption) => {
    dispatch(setZoneSuccess(option));
    setUrlSearchParams(IUrlParam.zoneSuccess, option.value);
  };

  const onChangeZoneFinish = (option: ISelectOption) => {
    dispatch(setZoneFinish(option));
    setUrlSearchParams(IUrlParam.zoneFinish, option.value);
  };

  const onChangeDumpInOutResult = (option: ISelectOption) => {
    dispatch(setDumpInOutResult(option));
    setUrlSearchParams(IUrlParam.dumpInOutResult, option.value);
  };

  const onChangeShootoutEndResult = (option: ISelectOption) => {
    dispatch(setShootoutEndResult(option));
    setUrlSearchParams(IUrlParam.shootoutEndResult, option.value);
  };

  const onChangeShootoutEndType = (option: ISelectOption) => {
    dispatch(setShootoutEndType(option));
    setUrlSearchParams(IUrlParam.shootoutEndType, option.value);
  };

  const onChangeShootoutEndSide = (option: ISelectOption) => {
    dispatch(setShootoutEndSide(option));
    setUrlSearchParams(IUrlParam.shootoutEndSide, option.value);
  };

  return {
    onChangeShotCategory,
    onChangeEntity,
    onChangeShotLocation,
    onChangeShotDanger,
    onChangeShotType,
    onChangeGameActionType,
    onChangeNetZone,
    onChangeZoneCategory,
    onChangeZoneSuccess,
    onChangeZoneFinish,
    onChangeDumpInOutResult,
    onChangeShootoutEndResult,
    onChangeShootoutEndType,
    onChangeShootoutEndSide,
  };
};
