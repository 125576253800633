import {
  shootoutEndResultOptions,
  shootoutEndTypeOptions,
  shootoutStickGripOptions,
  shotoutEndSideOptions,
} from '../../constants';
import { IShootoutFilterState, IUrlParam } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { parseUrlStaticSelectOption } from '../url.utils';

export const parseUrlShootoutFilterParams = (
  searchParams: URLSearchParams,
): Partial<IShootoutFilterState> => {
  const endResult = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shootoutEndResult,
    shootoutEndResultOptions,
  );
  const endType = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shootoutEndType,
    shootoutEndTypeOptions,
  );
  const endSide = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shootoutEndSide,
    shotoutEndSideOptions,
  );
  const shootoutStickGrip = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shootoutStickGrip,
    shootoutStickGripOptions,
  );

  return {
    endResult: endResult || getDefaultPredefinedOptionByValue(shootoutEndResultOptions),
    endType: endType || getDefaultPredefinedOptionByValue(shootoutEndTypeOptions),
    endSide: endSide || getDefaultPredefinedOptionByValue(shotoutEndSideOptions),
    stickGrip: shootoutStickGrip || getDefaultPredefinedOptionByValue(shootoutStickGripOptions),
  };
};
