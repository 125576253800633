import { FC, ReactNode, RefObject } from 'react';

import { IEntity } from '../../../types';
import { createClassNames, handleScroll } from '../../../utils';
import { ScrollButton } from '../../ScrollButton';
import { ITableFilterProps, TableFilter } from '../TableFilter';
import './TableToolbar.styles.scss';

export interface ITableToolbarProps extends ITableFilterProps {
  centerTablePartRef?: RefObject<HTMLDivElement>;
  disableScrollButtons?: boolean;
  scrollSizePx?: number;
  exportButton?: ReactNode;
  isLoading?: boolean;
}

const classNames = createClassNames('table-toolbar');

export const TableToolbar: FC<ITableToolbarProps> = ({
  centerTablePartRef,
  dataTemplateEntity = IEntity.players,
  disableScrollButtons,
  disablePositionSelection,
  enableCountOfPlayers,
  enableGamePartField,
  submittedGoalkeeperOption,
  submittedPlayerOption,
  submittedTeamOption,
  scrollSizePx = 100,
  isPercentilesLoading,
  isPercentilesActive,
  onClickLeaguePercentile,
  exportButton,
  extraElement,
  percentilesButtonLabel,
  isSelectInputsDropUp,
  isLoading = false,
  breakElements,
}) => (
  <div className={classNames({ disabled: isLoading })}>
    <TableFilter
      {...{
        dataTemplateEntity,
        disablePositionSelection,
        enableCountOfPlayers,
        enableGamePartField,
        submittedGoalkeeperOption,
        submittedPlayerOption,
        submittedTeamOption,
        onClickLeaguePercentile,
        extraElement,
        isPercentilesLoading,
        isPercentilesActive,
        percentilesButtonLabel,
        isSelectInputsDropUp,
        breakElements,
      }}
    />
    <div className={classNames('right-part')}>
      {exportButton && (
        <div className={classNames('right-part__export-button')}>{exportButton}</div>
      )}
      {!disableScrollButtons ? (
        <div className={classNames('right-part__scroll-buttons')}>
          <ScrollButton
            isLeft
            onClick={() => handleScroll(centerTablePartRef, 'left', scrollSizePx)}
          />
          <ScrollButton onClick={() => handleScroll(centerTablePartRef, 'right', scrollSizePx)} />
        </div>
      ) : null}
    </div>
  </div>
);
