import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  faceoffResultOptions,
  faceoffZoneOptions,
  faceoffsFollowedOptions,
  stickGripOptions,
} from '../../../constants';
import { IFaceoffFilterState, ISelectOption } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IFaceoffFilterState = {
  stickGrip: getDefaultPredefinedOptionByValue(stickGripOptions),
  faceoffResult: getDefaultPredefinedOptionByValue(faceoffResultOptions),
  faceoffFollowedBy: getDefaultPredefinedOptionByValue(faceoffsFollowedOptions),
  faceoffZone: getDefaultPredefinedOptionByValue(faceoffZoneOptions),
};

export const faceoffsFilterSlice = createSlice({
  name: 'faceoffsFilter',
  initialState,
  reducers: {
    setFaceoffStickGrip: (state, action: PayloadAction<ISelectOption>) => {
      state.stickGrip = action.payload;
    },
    setFaceoffResult: (state, action: PayloadAction<ISelectOption>) => {
      state.faceoffResult = action.payload;
    },
    setFaceoffFollowedBy: (state, action: PayloadAction<ISelectOption>) => {
      state.faceoffFollowedBy = action.payload;
    },
    setFaceoffZone: (state, action: PayloadAction<ISelectOption>) => {
      state.faceoffZone = action.payload;
    },
    resetFaceoffsFilter: (_, action: PayloadAction<Partial<IFaceoffFilterState> | undefined>) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setFaceoffStickGrip,
  setFaceoffResult,
  setFaceoffFollowedBy,
  setFaceoffZone,
  resetFaceoffsFilter,
} = faceoffsFilterSlice.actions;
