import { ITranslationKeys } from '../i18n/types';
import { INavigationContentKeys } from './navigation.types';

export const IMetricVideoLinkKeys = {
  shots: 'shots',
  passes: 'passes',
  faceoffs: 'faceoffs',
  entries: 'entries',
  exits: 'exits',
  shootouts: 'shootouts',
  goalkeepersShootouts: 'goalkeepersShootouts',
  goalkeepersShots: 'goalkeepersShots',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IMetricVideoLinkKeys = (typeof IMetricVideoLinkKeys)[keyof typeof IMetricVideoLinkKeys];

export interface IMetricVideoLinkConfig {
  translationKey: ITranslationKeys;
  contentKey: INavigationContentKeys;
}
export type IMetricVideoLinkConfigRecord = Record<IMetricVideoLinkKeys, IMetricVideoLinkConfig>;
