import {
  ICompetitionDetail,
  IGame,
  IGameRecord,
  INetZone,
  INetZoneUnion,
  IPass,
  IPlayer,
  IPlayerRecord,
  ISelectOption,
  ISelectedPlayersRecord,
  IShot,
  IVideomapsFilterState,
  IZoneEntity,
} from '../types';

const filterBySelectedPlayers = <T extends IShot | IPass | IZoneEntity>(
  array: T[],
  selectedPlayerItems: ISelectedPlayersRecord,
): T[] => {
  const onPlayers = Object.values(selectedPlayerItems).filter(player => player.isActive);
  return array.filter(item =>
    onPlayers.some(playerItem => playerItem.selectedPlayer?.value === item.playerId),
  );
};

const filterByGames = <T extends IShot | IPass | IZoneEntity>(
  selectedGames: ISelectOption[],
  array: T[],
  selectedPlayerItems?: ISelectedPlayersRecord,
) =>
  selectedGames.reduce<T[]>((acc, game) => {
    const gameArray = array.filter(item => item.matchId === game.value);

    if (selectedPlayerItems) {
      const filteredByPlayers = filterBySelectedPlayers(gameArray, selectedPlayerItems);
      return acc.concat(filteredByPlayers);
    }

    return acc.concat(gameArray);
  }, []);

export const filterByEntity = <T extends IShot | IPass | IZoneEntity>(
  entity: ISelectOption,
  array: T[],
  arrayAgainst: T[],
) => {
  if (entity.value === 'team') {
    return array;
  }

  if (entity.value === 'opponent' || entity.value === 'opponentTeam') {
    return arrayAgainst;
  }

  return array;
};

// TODO Jirka - možná použít
// export const filterByEntityId = <T extends IShot | IPass | IZoneEntity>(
//   entity: ISelectOption,
//   array: T[],
//   entityId?: string,
//   filterByProp?: string,
// ) => {
//   if (entityId && (entity.value === 'player' || filterByProp === 'blocker')) {
//     return array.filter(item => item[(filterByProp as keyof T) || 'playerId'] === entityId);
//   }

//   return array;
// };

export const filterBySelectedGames = <T extends IShot | IPass | IZoneEntity>(
  videomapsFilterState: IVideomapsFilterState,
  array: T[],
  selectedGames: ISelectOption[],
  customGames?: ISelectOption[],
) => {
  const { entity, selectedPlayerItems } = videomapsFilterState;

  const games = customGames ?? selectedGames;

  if (entity.value === 'player') {
    return filterByGames(games, array, selectedPlayerItems);
  }

  return filterByGames(games, array);
};

export const filterShotsByGameActionType = (
  gameActionType: ISelectOption,
  filteredShots: IShot[],
) => {
  if (gameActionType.value === 'f') return filteredShots.filter(shot => shot.forecheck);

  if (gameActionType.value === 'r') return filteredShots.filter(shot => shot.rush);

  if (gameActionType.value === 'l') return filteredShots.filter(shot => shot.long);

  if (gameActionType.value === 'o') return filteredShots.filter(shot => shot.oddManRush);

  if (gameActionType.value === 'a') return filteredShots.filter(shot => shot.afterFO);

  return filteredShots;
};

export const filterGames = (selectedGames: ISelectOption[], gamesRecord: IGameRecord) =>
  selectedGames.reduce<IGame[]>((acc, selectedGame) => {
    const game = gamesRecord[selectedGame.value];
    if (game) acc.push(game);

    return acc;
  }, []);

export const countAllGamesTotalToi = (filteredGames: IGame[]) =>
  filteredGames.reduce<number>((acc, game) => {
    if (game.toi) {
      acc += game.toi;
    }

    return acc;
  }, 0);

export const filterShotsByCategory = (shotCategory: ISelectOption, filteredShots: IShot[]) => {
  if (shotCategory.value === 'SG')
    return filteredShots.filter(shot => shot.type === 'S' || shot.type === 'G');
  if (shotCategory.value === 'S') return filteredShots.filter(shot => shot.type === 'S');
  if (shotCategory.value === 'G') return filteredShots.filter(shot => shot.type === 'G');
  if (shotCategory.value === 'M') return filteredShots.filter(shot => shot.type === 'M');
  if (shotCategory.value === 'B') return filteredShots.filter(shot => shot.type === 'B');
  if (shotCategory.value === 'P') return filteredShots.filter(shot => shot.type === 'P');

  return filteredShots;
};

export const filterShotsByType = (filteredShots: IShot[], shotType?: ISelectOption) => {
  if (!shotType) return filteredShots;

  if (shotType.value === '1t') return filteredShots.filter(shot => shot.oneTimer);

  if (shotType.value === 'reb') return filteredShots.filter(shot => shot.rebounds);

  if (shotType.value === 's') return filteredShots.filter(shot => shot.screeningPlayer);

  if (shotType.value === 'd') return filteredShots.filter(shot => shot.deflected);

  return filteredShots;
};

export const filterShotsByDanger = (shotDanger: ISelectOption, filteredShots: IShot[]) => {
  if (shotDanger.value === 'hd') return filteredShots.filter(shot => shot.shotDanger === 'hd');

  if (shotDanger.value === 'md') return filteredShots.filter(shot => shot.shotDanger === 'md');

  if (shotDanger.value === 'ld') return filteredShots.filter(shot => shot.shotDanger === 'ld');

  return filteredShots;
};

export const filterShotsByNetZone = (filteredShots: IShot[], netZone?: INetZoneUnion) => {
  if (!netZone) return filteredShots;

  if (netZone === INetZone.br) return filteredShots.filter(shot => shot.netZone === INetZone.br);
  if (netZone === INetZone.bl) return filteredShots.filter(shot => shot.netZone === INetZone.bl);
  if (netZone === INetZone.ce) return filteredShots.filter(shot => shot.netZone === INetZone.ce);
  if (netZone === INetZone.fh) return filteredShots.filter(shot => shot.netZone === INetZone.fh);
  if (netZone === INetZone.ul) return filteredShots.filter(shot => shot.netZone === INetZone.ul);
  if (netZone === INetZone.ur) return filteredShots.filter(shot => shot.netZone === INetZone.ur);

  return filteredShots;
};

export const filterPlayersByPosition = (
  players: IPlayer[],
  position: ISelectOption | undefined,
) => {
  if (position && position.value !== 'all') {
    return players.filter(player => player.position === position.value);
  }

  return players;
};

export const filterAllTeamPlayersByParts = (
  playersRecordById: IPlayerRecord,
  parts: ICompetitionDetail[],
) => {
  const filteredPlayers = parts
    .reduce<IPlayer[]>((filteredPlayers, part) => {
      const playersInTeamInFilteredParts: IPlayer[] = [];

      Object.values(part.teamPlayers).forEach(team => {
        playersInTeamInFilteredParts.push(
          ...team.playerIds.map(playerId => playersRecordById[playerId]),
        );
      });

      return filteredPlayers.concat(playersInTeamInFilteredParts);
    }, [])
    .filter((value, index, self) => self.indexOf(value) === index);

  return filteredPlayers;
};
