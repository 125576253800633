import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DEFAULT_CONTENT_TEMPLATE_NAMES } from '../../constants';
import {
  filteredMainFilterDataSelector,
  getTeamsFormation,
  getWowy,
  resetTeamsFormationLoadedData,
  resetWowyLoadedData,
  selectMainFilter,
  selectTeamsFormation,
  setIsTeamsFormationPercentilesActive,
  setIsWowyPercentilesActive,
} from '../../features';
import { IMainFilterForm, ITeamsFormationRequestBody } from '../../types';
import { normalizeSelectWithAllValues } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchTeamsFormation = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { isTeamsFormationPercentilesActive } = useAppSelector(selectTeamsFormation);
  const { selectedPlayer, selectedTeam, formationType } = useAppSelector(selectMainFilter);
  const { filteredParts, filteredTeams } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTeamsFormation = (data: Partial<IMainFilterForm>, isPercentiles?: boolean) => {
    if (!isPercentiles) {
      dispatch(resetTeamsFormationLoadedData());
      dispatch(resetWowyLoadedData());
    }

    if (isTeamsFormationPercentilesActive) {
      dispatch(setIsTeamsFormationPercentilesActive(false));
      dispatch(setIsWowyPercentilesActive(false));
    }

    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: ITeamsFormationRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES['formations']),
      gameState: normalizeSelectWithAllValues(formationType),
    };

    if (selectedTeam && selectedTeam.value !== 'all') {
      const sharedRequestParams = {
        competitionsUuids,
        body: requestBody,
        teamUuid: selectedTeam.value,
      };

      if (isPercentiles) {
        dispatch(
          getTeamsFormation({
            ...sharedRequestParams,
            isPercentile: true,
          }),
        );
      } else {
        dispatch(getTeamsFormation(sharedRequestParams));
      }
      if (selectedPlayer) {
        if (isPercentiles) {
          dispatch(
            getWowy({
              ...sharedRequestParams,
              playerUuid: selectedPlayer.value,
              isPercentile: true,
            }),
          );
        } else {
          dispatch(
            getWowy({
              ...sharedRequestParams,
              playerUuid: selectedPlayer.value,
            }),
          );
        }
      }
    }

    if (selectedTeam && selectedTeam.value === 'all') {
      if (isPercentiles) {
        filteredTeams.forEach(team => {
          dispatch(
            getTeamsFormation({
              competitionsUuids,
              body: requestBody,
              teamUuid: team.id,
              isPercentile: true,
            }),
          );
        });
      } else {
        filteredTeams.forEach(team => {
          dispatch(
            getTeamsFormation({
              competitionsUuids,
              body: requestBody,
              teamUuid: team.id,
            }),
          );
        });
      }
    }
  };

  return fetchTeamsFormation;
};
