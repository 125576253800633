import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import {
  selectDataSettingsFilter,
  selectFormations,
  selectMetrics,
  selectTeams,
  setIsTeamOrderActive,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchTeamsStats,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IEntity, IExportRows, IFormationStatsTableData } from '../../../../types';
import { createClassNames, handleExportToXLSX, roundNumberTo2Decimals } from '../../../../utils';
import './TabsContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

const classNames = createClassNames('tabs-content');

export const TabsContent = () => {
  const { t } = useTranslation();
  const { isLoading, isTeamOrderActive, isTeamOrderLoading } = useAppSelector(selectFormations);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchTeamsStats = useFetchTeamsStats();
  const handleOnSubmit = useHandleOnSubmit((values, config) => {
    fetchTeamsStats(values, config?.teamOrder);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const handleToggleTeamOrder = () => {
    dispatch(setIsTeamOrderActive(!isTeamOrderActive));
    if (!isTeamOrderActive) {
      handleOnSubmit({ teamOrder: true });
    }
  };

  const commonHeader = [
    t(ITranslationKeys.teamName),
    t(ITranslationKeys.teamShortName),
    'GP',
    'TOI',
  ];
  const tableRowCallback = (row: IFormationStatsTableData): IExportRows => {
    const team = teams.byId[row.teamId];

    return {
      teamName: team.name,
      teamShortcut: team.shortcut,
      gp: row.gp,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  if (isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='teams-page__tabs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        isPercentilesActive={isTeamOrderActive}
        isPercentilesLoading={isTeamOrderLoading}
        onClickLeaguePercentile={handleToggleTeamOrder}
        scrollSizePx={130}
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.teamsTable),
                commonHeader,
                tableRowCallback,
                data,
              )
            }
          />
        }
        percentilesButtonLabel={ITranslationKeys.leagueOrder}
        dataTemplateEntity={IEntity.teams}
        disablePositionSelection
      />
      <CommonTable<IFormationStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.teams}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          onIceName={ITranslationKeys.teamsData}
          entity={IEntity.teams}
        />
      )}
    </div>
  );
};
