import { ColumnDef, ColumnPinningState, SortingState } from '@tanstack/react-table';
import { ReactElement } from 'react';

export type IModifiers = Record<string, string | boolean | number | undefined>;

export type IMultiParamsVoid = (...event: any[]) => void;

export type IStats = Record<string, number>;

export type ILeftRight = 'left' | 'right';

export type ISize = 'small' | 'medium' | 'large';

export interface ICoordinates {
  x: number;
  y: number;
}

interface IBaseDatasetMetric {
  code: string;
  origin: string;
  parameter: boolean;
}

export interface IDatasetMetric extends IBaseDatasetMetric {
  value: number | null;
}

export type IDatasetMetricRecord = Record<string, IDatasetMetric>;

export interface IDataset {
  id: string;
  name: string;
  datasetMetrics: IDatasetMetricRecord;
}

export type IDatasetRecord = Record<string, IDataset>;

interface IAfterEvent {
  event: string;
  length: number;
}

export interface IVideoCut {
  cutBefore: number;
  cutAfter: number;
}

export interface IVideoIdAndTime {
  videoId: string;
  videoTime: number;
}

export interface IMatchIdAndDate {
  matchId: string;
  matchDate: string;
}

export interface ISharedVideoInfo extends IVideoIdAndTime, IMatchIdAndDate {
  playerId: string;
  time: number;
}

export interface ISharedVideo extends ISharedVideoInfo, IVideoCut {
  videoUuid: string;
}

export interface IVideoInfoBase {
  homeTeamId?: string;
  awayTeamId?: string;
  realTime?: string;
  score?: IScore;
}

export interface IVideoInfo extends ISharedVideoInfo, IVideoInfoBase {}

export interface IVideoInfoDTO extends IVideoIdAndTime {
  match: string;
  matchDate: string;
  player: string;
  homeTeam: string;
  awayTeam: string;
  time: number;
  score: IScore;
  realTime: string;
}

export interface TranslationFunctionOptions {
  /** Count value used for plurals */
  count?: number;
  /** Used for contexts (eg. male\female) */
  context?: string;
}

/** Base option type for select inputs. */
export interface ISelectOptionBase {
  /** Option label */
  label: string;
  /** Option value */
  value: string;
  /** Does this option select all? */
  isAllOption?: boolean;
  /** An additional value to hold the extra information (e.g. teamId for player). */
  additionalValue?: string;
  /** Translation options for label (e.g. plural, context). */
  labelTranslationsOptions?: TranslationFunctionOptions;
  /** Additional react node displayed next to the text */
  icon?: ReactElement;
}

/** Option type extended by generic additional info. */
export interface ISelectOptionWithParam<T> extends ISelectOptionBase {
  /** An additional info to hold the extra information. */
  additionalInfo: T;
}

/** Option type for select inputs. */
export type ISelectOption<TOption = undefined> = TOption extends undefined
  ? ISelectOptionBase
  : ISelectOptionWithParam<TOption>;

export interface IUseMultiselectConfig<TOption = undefined> {
  /** Available select options */
  options: ISelectOption<TOption>[];
  /** Selected options. */
  selected: ISelectOption<TOption>[];
  /** Function called when other options are picked. */
  onChange: IMultiParamsVoid;
  /** Adds select all option. */
  hasSelectAllOption?: boolean;
}

export interface ITimeFromTo {
  timeFrom?: number;
  timeTo?: number;
}

export interface ILineup {
  line: number;
  position: string;
}

export interface IStatsWithId {
  id: string;
  stats: IStats;
}

export interface IStatsWithPercentiles extends IStatsWithId {
  percentiles?: IStats;
}

export interface IPlayerWithTeamIdAndStats extends IStatsWithId {
  teamId: string;
}

export interface IPlayerWithTeamIdAndStatsAndLineup extends IPlayerWithTeamIdAndStats, ILineup {}

export interface IHomeAwayTeams<T extends {}> {
  homeTeam: T;
  awayTeam: T;
}

export interface IFormation {
  player: string;
  on: boolean;
}

export interface IBaseGamesRequestBody {
  dateFrom?: string;
  dateTo?: string;
  lastPlayedMatches?: number;
}

export interface IBaseRequestBody extends IBaseGamesRequestBody, ITimeFromTo {
  gameState?: string;
  scoreState?: string;
  place?: string;
  opponentTeams?: string[];
  timeOnIce?: number;
  afterEvent?: IAfterEvent;
}

export interface IBaseRequestBodyWithFormation extends IBaseRequestBody {
  formation?: IFormation[];
}

export interface IBaseRequestBodyWithMetrics extends IBaseRequestBody {
  metrics?: string[];
}

export type ILogoPaths = Record<string, string>;

export const IScoreState = {
  normal: 'normal',
  overtime: 'overtime',
  shooting: 'shooting',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IScoreState = (typeof IScoreState)[keyof typeof IScoreState];

export interface IScore {
  home: number;
  away: number;
  state: IScoreState;
}

export interface IExportRows {
  [key: string]: string | number;
}

export interface IExportData {
  /** Sets custom set of strings as export table header. */
  exportHeader?: string[][];
  /** Custom width of each table column. */
  columnsWidth?: { width: number }[];
  /** Array of export data. */
  exportData: IExportRows[];
}

export interface IBaseColumnsConfig<T> {
  columns: ColumnDef<T, any>[];
}

export interface IColumnsConfig<T> extends IBaseColumnsConfig<T> {
  columnPinning: ColumnPinningState;
  initialSorting?: SortingState;
}
export interface IPercentilesConfig {
  isPercentilesActive: boolean | undefined;
  isPercentilesLoading: boolean | undefined;
  isOrder?: boolean;
}

export const IPlayerColors = {
  red: 'red',
  purple: 'purple',
  cyan: 'cyan',
  teal: 'teal',
  orange: 'orange',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IPlayerColors = (typeof IPlayerColors)[keyof typeof IPlayerColors];

export const IOnOffColors = {
  off: 'off',
  on: 'on',
  blue: 'blue',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IOnOffColors = (typeof IOnOffColors)[keyof typeof IOnOffColors];

export interface IShotFlowGraphPlayerInfo {
  /**
   * Name of the player.
   */
  name: string;
  /**
   * Time on ice of the player.
   */
  toi: string;
  /**
   * Corsi for percentage of the player.
   */
  cf_percent: number;
  /**
   * Corsi for of the player.
   */
  cf: number;
  /**
   * Corsi against of the player.
   */
  ca: number;
}

export interface IShotFlowGraphPlayerWithCoordinates extends ICoordinates {
  /** Player info to be displayed in the tooltip. */
  playerInfo: IShotFlowGraphPlayerInfo;
  /** Team of the player. */
  team: 'home' | 'away';
  /** Size of the circle. */
  size: number;
}

export interface IShotFlowGraphConfig {
  /** Array of items (circles) to be displayed in the ShotFlowGraph component. */
  items: IShotFlowGraphPlayerWithCoordinates[];
  /** Label displayed upon the graph. */
  teamShortcut: string;
  /** Minimal value of CA & CF. */
  min: number;
  /** Maximal value of CA & CF. */
  max: number;
}

export interface IShotFlowGraphConfigs {
  home: IShotFlowGraphConfig;
  away: IShotFlowGraphConfig;
}

export interface IHandleOnSubmitConfig {
  isPercentiles?: boolean;
  teamOrder?: boolean;
  isInitHeatmap?: boolean;
}

export interface IHeatmapData extends ICoordinates {
  z: IStats;
}

export interface IHeatmap {
  for: IHeatmapData[];
  against: IHeatmapData[];
}

export interface IHeatmapMetricData extends ICoordinates {
  value: number;
}

export const IEntity = {
  players: 'players',
  teams: 'teams',
  goalkeepers: 'goalkeepers',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IEntity = (typeof IEntity)[keyof typeof IEntity];

export interface IGoalNetBoxItem {
  /** String (or translated string) to be displayed in the table */
  label?: string;
  /** Value of the label */
  value: string;
}
export interface IGoalStatsBoxItem {
  /** String (or translated string) to be displayed in the table */
  label?: string;
  /** Value of the label */
  value: string;
}

export interface IGoalStatsBoxProps {
  /** Array of items to be displayed in the table */
  items: IGoalNetBoxItem[];
  /** If true, the play icon will be displayed */
  enablePlayIcon?: boolean;
  /** If true, the table will be horizontal */
  isHorizontal?: boolean;
  /** Variant of the box
   * @default default
   */
  color?: IDefaultPlayerColorNames;
  /** Variant of the box
   * @default light
   */
  variant?: 'light' | 'dark';
  /**
   * Weight of the box text
   * @default normal
   * normal = 400
   * medium = 500
   * semiBold = 600
   * bold = 700
   */
  fontWeight?: 'normal' | 'medium' | 'semiBold' | 'bold';
  /** Fired when user clicks on the box. */
  onClick?: () => void;
}

export interface IGoalStatsBoxWrapperProps {
  /** Array of items to be displayed in the table */
  goalStatsBoxItems: IGoalStatsBoxProps[];
  /** Label of the table */
  label?: string | null;
  /** Color of the box
   * @default default
   */
  color?: IDefaultPlayerColorNamesWithBlack;
  /** If true, the table header is shown */
  showHeader?: boolean;
  /** If true, box shadow is hidden */
  disableBoxShadow?: boolean;
  /** Fired when user clicks on the box. */
  onClick?: () => void;
}

export type IDefaultPlayerColorNames = IPlayerColors | 'default';
export type IDefaultPlayerColorNamesWithBlack = IDefaultPlayerColorNames | 'black';

export interface IApexChartConfig {
  chart: ApexChart;
  markers: ApexMarkers;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  annotations: ApexAnnotations;
  legend: ApexLegend;
  grid: ApexGrid;
}

export type IClipRating = 'thumbs-up' | 'info' | 'thumbs-down';

export interface INumberRange {
  from?: number;
  to?: number;
}

export interface IChangelogChangeText {
  cz: string;
  en: string;
}

export interface IChangelogItem {
  id: string;
  date: string;
  changes: IChangelogChangeText[];
}

export type IChangelog = Record<string, IChangelogItem>;

export const IUrlParam = {
  selectedSeasons: 'selectedSeasons',
  selectedParts: 'selectedParts',
  selectedTeam: 'selectedTeam',
  selectedPlayer: 'selectedPlayer',
  selectedGoalkeeper: 'selectedGoalkeeper',
  selectedOpponents: 'selectedOpponents',
  selectedPlayerItems: 'selectedPlayerItems',
  selectedGames: 'selectedGames',
  entity: 'entity',
  shotCategory: 'shotCategory',
  shotLocation: 'shotLocation',
  shotDanger: 'shotDanger',
  shotType: 'shotType',
  netZone: 'netZone',
  gameActionType: 'gameActionType',
  countOfPlayers: 'countOfPlayers',
  place: 'place',
  scoreState: 'scoreState',
  formationType: 'formationType',
  lastPlayedMatches: 'lastPlayedMatches',
  gameTimeFrom: 'gameTimeFrom',
  gameTimeTo: 'gameTimeTo',
  timeOnIce: 'timeOnIce',
  dateFromTo: 'dateFromTo',
  opponentsAtGamesPage: 'opponentsAtGamesPage',
  goalkeeperSelectedGames: 'goalkeeperSelectedGames',
  passType: 'passType',
  faceoffResult: 'faceoffResult',
  faceoffStickGrip: 'faceoffStickGrip',
  faceoffFollowedBy: 'faceoffFollowedBy',
  zoneCategory: 'zoneCategory',
  zoneSuccess: 'zoneSuccess',
  zoneFinish: 'zoneFinish',
  zoneExitResult: 'zoneExitResult',
  dumpInOutResult: 'dumpInOutResult',
  shootoutEndResult: 'shootoutEndResult',
  shootoutEndType: 'shootoutEndType',
  shootoutEndSide: 'shootoutEndSide',
  shootoutStickGrip: 'shootoutStickGrip',
  goalkeeperStickGrip: 'goalkeeperStickGrip',
  goalkeeperSaves: 'goalkeeperSaves',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IUrlParam = (typeof IUrlParam)[keyof typeof IUrlParam];

export interface IUrlFilterParams {
  name: string;
  value: string;
}

export interface IBaseTcmsFilterForm {
  selectedTeamOrMedia: ISelectOption[];
  selectedRole?: ISelectOption;
}

export interface IBaseTrackingFilterForm {
  selectedRoles: ISelectOption[];
  selectedTeamOrMedia: ISelectOption[];
}

export interface IPaginationParams {
  offset: number;
  limit?: number;
}

export interface ISharedLogParams extends IPaginationParams {
  from?: string;
  to?: string;
  username?: string;
}

export const IZonesFetchDataMode = {
  entry: 'entry',
  dumpInOut: 'dumpInOut',
  both: 'both',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IZonesFetchDataMode = (typeof IZonesFetchDataMode)[keyof typeof IZonesFetchDataMode];
