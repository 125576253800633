import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  closeShiftsVideoCenter,
  filteredMainFilterDataSelector,
  getVideoShifts,
  openVideoCenter,
  openVideoCenterMetrics,
  resetShiftVideoEditById,
  setMetricVideoDatasetMetric,
  setMetricVideoLinkId,
  setOpenShiftsVideoCenter,
  setSelectedGames,
  setShiftVideoEdit,
  setVideoEntityId,
  setVideoTeamId,
} from '../features';
import { ITranslationKeys } from '../i18n/types';
import {
  IDatasetMetric,
  IGameEntity,
  IMainFilterForm,
  IMetricVideoIds,
  ISelectOption,
  IVideoEdit,
  IVideoInfo,
  IVideoRecord,
} from '../types';
import { createVideoRecordFromArray } from '../utils';
import { useFetchDataByMetricLinkId } from './videoMetricLink';

export const useVideoCenter = <T extends IVideoInfo>(
  gameEntities: IGameEntity[] = [],
  categoryKey: ITranslationKeys | string = '',
  isGoalkeeper: boolean = false,
) => {
  const dispatch = useAppDispatch();

  const playGameVideos = (gameId: string) => {
    const videoRecord = gameEntities.reduce<IVideoRecord>(
      (acc, game) =>
        game.gameId === gameId ? { ...acc, ...createVideoRecordFromArray(game.entities) } : acc,
      {},
    );

    if (Object.values(videoRecord).length === 0) return;

    dispatch(
      openVideoCenter({
        categoryKey,
        open: true,
        videoRecord,
        isGoalkeeper,
      }),
    );
  };

  const playSelectedGamesVideos = () => {
    const videoRecord = gameEntities.reduce<IVideoRecord>(
      (acc, game) => ({ ...acc, ...createVideoRecordFromArray(game.entities) }),
      {},
    );

    if (Object.values(videoRecord).length === 0) return;

    dispatch(
      openVideoCenter({
        categoryKey,
        open: true,
        videoRecord,
        isGoalkeeper,
      }),
    );
  };

  const playVideos = (array: T[]) => {
    if (array.length > 0) {
      dispatch(
        openVideoCenter({
          categoryKey,
          open: true,
          videoRecord: createVideoRecordFromArray(array),
          isGoalkeeper,
        }),
      );
    }
  };

  return {
    playGameVideos,
    playSelectedGamesVideos,
    playVideos,
  };
};

export const useVideoCenterShifts = () => {
  const { filteredParts, selectedGame } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const playPlayerShiftVideos = (playerId: string) => {
    if (!selectedGame) return;

    dispatch(
      getVideoShifts({
        competitionsUuid: filteredParts[0].id,
        matchUuid: selectedGame?.id,
        playerUuid: playerId,
      }),
    );
    dispatch(setOpenShiftsVideoCenter(true));
  };

  const handleSetVideoEdit = (edit: IVideoEdit) => {
    dispatch(setShiftVideoEdit(edit));
  };

  const handleClose = () => {
    dispatch(closeShiftsVideoCenter());
  };

  const handleResetVideoEdit = (videoUuid: string) => {
    dispatch(resetShiftVideoEditById(videoUuid));
  };

  return {
    playPlayerShiftVideos,
    handleSetVideoEdit,
    handleClose,
    handleResetVideoEdit,
  };
};

export const useVideoCenterFromMetric = () => {
  const dispatch = useAppDispatch();

  const { reset, handleSubmit } = useFormContext<IMainFilterForm>();

  const { fetchDataByMetricLinkId } = useFetchDataByMetricLinkId();

  const playVideoFromMetric = (
    metricIds: IMetricVideoIds,
    entityId?: string,
    teamId?: string,
    gameIds?: string[],
    datasetMetric?: IDatasetMetric,
  ) => {
    handleSubmit(values => {
      reset(values);
      fetchDataByMetricLinkId(values, metricIds, entityId, teamId);
    })();

    dispatch(openVideoCenterMetrics(true));
    dispatch(setMetricVideoLinkId(metricIds.metricLinkId));
    dispatch(setVideoEntityId(entityId));
    dispatch(setVideoTeamId(teamId));
    dispatch(setMetricVideoDatasetMetric(datasetMetric));

    if (gameIds) {
      dispatch(setSelectedGames(gameIds.map<ISelectOption>(id => ({ value: id, label: id }))));
    }
  };

  return {
    playVideoFromMetric,
  };
};
