import { FC, MutableRefObject, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import {
  Caption,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectInput,
  TableToolbar,
} from '../../../../../../components';
import {
  countOfPlayersDefaultOption,
  countOfPlayersOptions,
  gamePartDefaultOption,
  gamePartOptions,
} from '../../../../../../constants';
import {
  selectDataSettingsFilter,
  selectGames,
  selectMetrics,
  selectTableFilter,
  selectedGameSelector,
  setGameCountOfPlayers,
  setGamePart,
  setOpenDataSettings,
} from '../../../../../../features';
import {
  useFetchReport,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../../../hooks';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IEntity, IGameTeamsTableData } from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import './TeamsTableBox.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export interface ITeamsTableBoxProps {
  /** Columns for table. */
  isInitialMountRef: MutableRefObject<boolean>;
  /** Are select inputs' options above inputs? */
  isSelectInputsDropUp?: boolean;
}

const classNames = createClassNames('teams-table-box');

export const TeamsTableBox: FC<ITeamsTableBoxProps> = ({
  isInitialMountRef,
  isSelectInputsDropUp,
}) => {
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const { gamePart, gameCountOfPlayers } = useAppSelector(selectTableFilter);
  const selectedGame = useAppSelector(selectedGameSelector);
  const { gamesReport } = useAppSelector(selectGames);
  const { isTeamsLoading } = gamesReport;
  const dispatch = useAppDispatch();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const { fetchGameTeams } = useFetchReport();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGameTeams(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading: isTeamsLoading });

  useEffect(() => {
    dispatch(setGamePart(gamePart ?? gamePartDefaultOption));
    dispatch(setGameCountOfPlayers(gameCountOfPlayers ?? countOfPlayersDefaultOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamePart, gameCountOfPlayers, selectedGame]);

  return (
    <div className={classNames()} data-testid='games-page__report-content__teams-table'>
      {isTeamsLoading && <Loading size='medium' backgroundColor='gray' disableMessage />}
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        percentilesButtonLabel={ITranslationKeys.leagueOrder}
        dataTemplateEntity={IEntity.teams}
        isSelectInputsDropUp={isSelectInputsDropUp}
        disablePositionSelection
        extraElement={
          <>
            <div>
              <Caption label={ITranslationKeys.gamePeriod} />
              <SelectInput
                onChange={value => dispatch(setGamePart(value))}
                selected={gamePart}
                options={gamePartOptions}
                placeholder={ITranslationKeys.defaultSelectPlaceholder}
                variant='filter'
              />
            </div>
            <div>
              <Caption label={ITranslationKeys.countOfPlayers} />
              <SelectInput
                onChange={value => dispatch(setGameCountOfPlayers(value))}
                selected={gameCountOfPlayers}
                options={countOfPlayersOptions}
                placeholder={ITranslationKeys.defaultSelectPlaceholder}
                variant='filter'
                dropUp={isSelectInputsDropUp}
              />
            </div>
          </>
        }
      />
      <CommonTable<IGameTeamsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.teams}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.teamsData}
          entity={IEntity.teams}
        />
      )}
    </div>
  );
};
