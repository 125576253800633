import { faceoffResultOptions, faceoffsFollowedOptions, stickGripOptions } from '../../constants';
import { IFaceoffFilterState, IUrlParam } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { parseUrlStaticSelectOption } from '../url.utils';

export const parseUrlFaceoffsFilterParams = (
  searchParams: URLSearchParams,
): Partial<IFaceoffFilterState> => {
  const stickGrip = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.faceoffStickGrip,
    stickGripOptions,
  );
  const faceoffResult = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.faceoffResult,
    faceoffResultOptions,
  );
  const faceoffFollowedBy = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.faceoffFollowedBy,
    faceoffsFollowedOptions,
  );

  return {
    stickGrip: stickGrip || getDefaultPredefinedOptionByValue(stickGripOptions),
    faceoffResult: faceoffResult || getDefaultPredefinedOptionByValue(faceoffResultOptions),
    faceoffFollowedBy:
      faceoffFollowedBy || getDefaultPredefinedOptionByValue(faceoffsFollowedOptions),
  };
};
